import { Alert, Box, TextField } from "@mui/material";
import { GetUserIfLoggedIn, useIdentity } from "contexts/identity-context";
import { Form, Formik } from "formik";
import { object, string } from "yup";

import { LoadingButton } from "@mui/lab";
import { GALTextInput } from "components/library/gal-text-input";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { Organisation } from "data/models";
import { submitNewOrganisation } from "data/mutations";
import { useMutation } from "react-query";
import { PredictiveAddressSearch } from "./pav-address-input";

export const orgCreationValidator = object({
  name: string()
    .required("Organisation name is required")
    .matches(
      /^[^\W_]/,
      "The organisation name must not start with a special character"
    ),
  email: string().email("Enter a valid email").required("Email is required"),
  abn: string()
    .matches(/^\d+$/, "ABN must be 11 digits")
    .min(11, "ABN must be 11 digits")
    .max(11, "ABN must be 11 digits"),
  address: string(),
  phoneNumber: string()
    .matches(
      /^\d+$/,
      "Please provide a valid Australian phone number without the country code"
    )
    .min(
      10,
      "Please provide a valid Australian phone number without the country code"
    )
    .max(
      10,
      "Please provide a valid Australian phone number without the country code"
    ),
});

export const CreateOrganisation = () => {
  const [identity, setIdentity] = useIdentity();

  const organisationMutation = useMutation(
    (organisation: Organisation) => submitNewOrganisation(organisation),
    {
      onSuccess: (newOrganisation) => {
        setIdentity((prevIdentity) => {
          const user = GetUserIfLoggedIn(prevIdentity);
          if (user) {
            return {
              ...user,
              organisations: [
                ...user.organisations,
                {
                  name: newOrganisation.name,
                  organisationId: newOrganisation.id,
                  active: false,
                },
              ],
            };
          } else {
            return prevIdentity;
          }
        });
      },
    }
  );

  return (
    <Box>
      <Formik<Organisation>
        enableReinitialize={true}
        initialValues={{
          name: "",
          email: "",
          abn: "",
          address: "",
          phoneNumber: "",
          id: "",
        }}
        onSubmit={async (values) => {
          organisationMutation.mutate(values);
        }}
        validationSchema={orgCreationValidator}
      >
        {({
          values: { name, email, abn, address, phoneNumber },
          setFieldValue,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => (
          <Form>
            <ReactQueryErrorWrapper
              queries={[]}
              mutations={[organisationMutation]}
            />

            {organisationMutation.isSuccess && (
              <Box marginBottom={1} pt={2}>
                <Alert variant="filled" severity="success">
                  Organisation has been created
                </Alert>
              </Box>
            )}

            <GALTextInput
              id="name"
              size="small"
              name="name"
              label="Organisation Display Name"
              type="text"
              margin="normal"
              value={name}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={touched.name && Boolean(errors.name)}
              errorText={errors.name}
              wasTouched={touched.name}
              variant="outlined"
              inputProps={{
                readOnly: organisationMutation.isLoading,
              }}
              characterLimit={64}
              fullWidth
            />

            <TextField
              inputProps={{
                readOnly: organisationMutation.isLoading,
                maxLength: 11,
              }}
              id="abn"
              size="small"
              name="abn"
              label="ABN"
              type="text"
              margin="normal"
              value={abn}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.abn && Boolean(errors.abn)}
              helperText={touched.abn && errors.abn}
              variant="outlined"
              fullWidth
            ></TextField>
            <TextField
              inputProps={{
                readOnly: organisationMutation.isLoading,
                maxLength: 10,
              }}
              id="phoneNumber"
              size="small"
              name="phoneNumber"
              label="Phone"
              type="text"
              margin="normal"
              value={phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.phoneNumber && Boolean(errors.phoneNumber)}
              helperText={touched.phoneNumber && errors.phoneNumber}
              variant="outlined"
              fullWidth
            ></TextField>

            <TextField
              inputProps={{
                readOnly: organisationMutation.isLoading,
                maxLength: 254,
              }}
              id="email"
              size="small"
              name="email"
              label="Organisation Email"
              type="email"
              margin="normal"
              value={email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              variant="outlined"
              fullWidth
            />

            <PredictiveAddressSearch
              readOnly={organisationMutation.isLoading || false}
              disabled={organisationMutation.isLoading || false}
              onChange={handleChange}
              onBlur={handleBlur}
              setFieldValue={setFieldValue}
              id="address"
              size="small"
              name="address"
              label="Address"
              value={address}
              error={
                address !== null && touched.address && Boolean(errors.address)
              }
              helperText={touched.address && errors.address}
            />
            <Box marginTop={2} display={"flex"} justifyContent={"flex-end"}>
              <LoadingButton
                loading={organisationMutation.isLoading}
                type="submit"
              >
                Create
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
