import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { createClipConfig, quoteClip } from "data/mutations";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { resolveIdentityId } from "utils/identity";
import { ClipConfigProps, ClipStepsControlProps } from "../../clip-menu";
import { ClipConfiguration, CRS, Format } from "../../models";
import { dataMappings } from "../step-2-choose-data/choose-data";
import { DataTree } from "./data-tree";

export const FinaliseClip = (
  props: ClipConfigProps & ClipStepsControlProps
) => {
  const [identityState] = useIdentity();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const isReadOnly = () => {
    return (
      props.clipConfig.status == "Quoting" ||
      props.clipConfig.status == "Processing" ||
      props.clipConfig.status == "Completed"
    );
  };

  const handleFormatChange = (event: SelectChangeEvent) => {
    props.setClipConfig((prev: ClipConfiguration) => ({
      ...prev,
      format: event.target.value as Format,
    }));
  };

  const { enqueueMutationFailed } = useCustomSnackbars();

  const clipsQuoteMutation = useMutation(
    (clipId: string) => quoteClip(clipId, identityState),
    {
      onSuccess: (clipConfig: ClipConfiguration) => {
        props.setClipConfig((prev: ClipConfiguration) => {
          return {
            ...prev,
            status: clipConfig.status,
          };
        });

        queryClient.invalidateQueries({
          queryKey: [QueryKeys.clipsKey, identityId],
        }),
          enqueueSnackbar("Clip has been submitted for quoting.", {
            variant: "success",
          });
        props.resetClipConfig();
      },
      onError: (error: Error) => {
        enqueueMutationFailed(error.toString());
      },
    }
  );

  const clipQueryKey = [QueryKeys.clipsKey, identityId];

  const saveAndQuoteMutation = useMutation(
    (clipConfig: ClipConfiguration) =>
      createClipConfig(clipConfig, identityState),
    {
      onSuccess: () => {
        clipsQuoteMutation.mutate(props.clipConfig.id);
      },
      onMutate: async (newConfig) => {
        await queryClient.cancelQueries(clipQueryKey);

        const previousConfig = queryClient.getQueriesData(clipQueryKey);

        queryClient.setQueriesData(clipQueryKey, () => {
          newConfig;
        });

        return { previousConfig };
      },
      onError: (error: Error, newConfig, context) => {
        queryClient.setQueriesData(clipQueryKey, context?.previousConfig);
        enqueueMutationFailed(error.toString());
      },
      onSettled: () => {
        queryClient.invalidateQueries(clipQueryKey);
      },
    }
  );

  const handleCrsChange = (event: SelectChangeEvent) => {
    props.setClipConfig((prev: ClipConfiguration) => ({
      ...prev,
      crs: event.target.value as CRS,
    }));
  };

  const onQuoteSubmit = () => {
    saveAndQuoteMutation.mutate(props.clipConfig);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      height="calc(86vh - 95px)"
      overflow="auto"
      sx={{
        gap: "16px",
        // maxHeight: "300px", // Set a fixed max height
        overflowY: "auto", // Enable vertical scrolling
        overflowX: "hidden", // Hide horizontal scrolling
      }}
    >
      <Typography variant="h5">Finalise Clip</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          gap: "16px",

          paddingTop: "16px",
        }}
      >
        <TextField
          fullWidth
          disabled={isReadOnly()}
          label="Name"
          value={props.clipConfig.name}
          inputProps={{ maxLength: 200 }}
          onChange={(event) =>
            props.setClipConfig((prev: ClipConfiguration) => ({
              ...prev,
              name: event.target.value,
            }))
          }
          variant="outlined"
          size="small"
        />
        <FormControl size="small">
          <InputLabel id="output-format-label">Format</InputLabel>
          <Select
            disabled={isReadOnly()}
            id="output-format"
            value={props.clipConfig.format ?? "SHP"}
            label="Format"
            onChange={handleFormatChange}
          >
            <MenuItem value="SHP">Esri Shapefile</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel id="output-crs-label">
            Coordinate Reference System
          </InputLabel>
          <Select
            disabled={isReadOnly()}
            id="output-crs"
            value={props.clipConfig.crs ?? "EPSG:4283"}
            label="Coordinate Reference System"
            onChange={handleCrsChange}
          >
            <MenuItem value="EPSG:4283">GDA94 (EPSG:4283)</MenuItem>
            <MenuItem value="EPSG:7844">GDA2020 (EPSG:7844)</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          disabled={isReadOnly()}
          label="Client Reference"
          value={props.clipConfig.clientReference}
          inputProps={{ maxLength: 200 }}
          onChange={(event) =>
            props.setClipConfig((prev: ClipConfiguration) => ({
              ...prev,
              clientReference: event.target.value,
            }))
          }
          variant="outlined"
          size="small"
        />
        <TextField
          fullWidth
          label="Notes"
          multiline
          disabled={isReadOnly()}
          rows={3}
          value={props.clipConfig.notes}
          inputProps={{ maxLength: 200 }}
          onChange={(event) =>
            props.setClipConfig((prev: ClipConfiguration) => ({
              ...prev,
              notes: event.target.value,
            }))
          }
          variant="outlined"
          size="small"
        />
        <Typography variant="subtitle1">Boundary</Typography>
        <Box display="block">
          {props.clipConfig.geometry?.features.map((feature, idx: number) => (
            <>
              {feature.properties && (
                <Tooltip
                  key={`tooltip-${idx}`}
                  placement="top"
                  title={feature.properties["name"]}
                >
                  <Chip
                    key={`chip-${idx}`}
                    sx={{ m: 0.2 }}
                    size="small"
                    label={feature.properties["name"]}
                  />
                </Tooltip>
              )}
            </>
          ))}
        </Box>
        <Typography variant="subtitle1">Data</Typography>
        {dataMappings.map((dataMapping) => (
          <DataTree
            dataMappings={dataMapping.children}
            selected={props.clipConfig.attributes ?? []}
          ></DataTree>
        ))}
      </Box>
      <Box alignSelf="flex-end" marginTop="auto" paddingTop={1} display="flex">
        <Button
          disabled={isReadOnly()}
          onClick={() => props.setActiveStep(Math.max(props.activeStep - 1, 0))}
        >
          Back
        </Button>
        <LoadingButton
          loading={
            clipsQuoteMutation.isLoading || saveAndQuoteMutation.isLoading
          }
          id="quote-clip"
          variant="text"
          disabled={
            !props.clipConfig.name ||
            clipsQuoteMutation.isLoading ||
            isReadOnly() ||
            saveAndQuoteMutation.isLoading
          }
          onClick={async () => {
            onQuoteSubmit();
          }}
        >
          Quote
        </LoadingButton>
      </Box>
    </Box>
  );
};
