import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { DemoItem } from "./demos-page";

export const DemoCard = (props: DemoItem & { status: string }) => {
  const classes = useStyles();

  const history = useHistory();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  return (
    <Card className={classes.item}>
      <CardActionArea
        onClick={() => {
          history.push(props.path);
        }}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <CardMedia
          sx={{ height: "100%", width: 250 }}
          component="img"
          src={isDark ? props.darkThumbnail : props.lightThumbnail}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              paddingBottom: "0px",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <CardHeader
              sx={{
                padding: "0px !important",
              }}
              title={props.title}
            ></CardHeader>
          </Box>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {props.categories.map((type: any) => (
                <Chip label={type} color="primary" size="small" />
              ))}
              <Chip
                label={props.status == "private" ? "Private" : "Public"}
                color={props.status == "private" ? "warning" : "secondary"}
                size="small"
              />
            </Box>
            <Typography variant="body2">{props.description}</Typography>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    padding: "0px",
    height: "250px",
    "&:hover": {
      background: "theme.palette.action.hover",
    },
  },
}));
