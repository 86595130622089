import { AlertColor } from "@mui/material";
import { CardElement } from "@stripe/react-stripe-js";
import { Stripe, StripeCardElement, StripeElements } from "@stripe/stripe-js";
import { IdentityState, User } from "contexts/identity-context";
import { OrganisationRow } from "contexts/models";
import {
  AdminAccountDetails,
  AlertState,
  ApiKey,
  ApigeeDeveloperStatus,
  CreateRelease,
  DatasetStatus,
  DemoStatus,
  DeveloperStatus,
  Organisation,
  RejectedInvite,
  Release,
  ReleaseStatus,
  UpdateSubscription,
  UserMembership,
} from "data/models";
import {
  AdminOrganisationDetail,
  AdminUserNote,
} from "pages/geoscape-admin/organisation/organisation-details/utils";
import { SubmitEditAdminUserSubscription } from "pages/geoscape-admin/organisation/organisation-subscription/types";
import { BatchConfig } from "pages/geoscape-batch/models";
import {
  ClipConfiguration,
  GeoJSONFeatureCollection,
} from "pages/geoscape-data/explorer/clip/models";
import { makeApiRequestPostToUrl, makeApiRequestToUrl } from "utils/api-client";
import { PlanDetails, roundDownOverageBudget } from "utils/calculations";
import { CustomDatasetsPost, Overage, PaymentMethod } from "./models";

interface ReactQueryMutationError {
  message: string;
  stack: string;
}

const getStripePaymentMethodToken = async (
  stripe: Stripe | null,
  stripeElements: StripeElements | null
) => {
  if (!stripe || !stripeElements) {
    throw new Error("Unable to access stripe. Please contact support.");
  }

  // Use elements.getElement to get a reference to the mounted Element.
  const cardElement: StripeCardElement | null =
    stripeElements.getElement(CardElement);
  if (!cardElement) {
    throw new Error("Unable to access stripe. Please contact support.");
  }

  // Pass the Element directly to other Stripe.js methods:
  const tokenResult = await stripe.createToken(cardElement);

  if (tokenResult.error) {
    throw new Error(tokenResult.error.message);
  }

  return tokenResult;
};

const adminAddPaymentMethod = async (
  stripe: Stripe | null,
  stripeElements: StripeElements | null,
  organisationId: string,
  setEditingCC: any
): Promise<PaymentMethod> => {
  const tokenResult = await getStripePaymentMethodToken(stripe, stripeElements);
  let paymentDetails: PaymentMethod[];

  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/organisation/${organisationId}/paymentMethod`,
      "POST",
      tokenResult.token
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages[0]);
    }

    setEditingCC(false);

    paymentDetails = responseData.filter(
      (paymentMethod: PaymentMethod) => paymentMethod.active
    );
  } catch (err) {
    throw new Error(
      `Unable to add payment method as admin for organisation '${organisationId}' : ${err}`
    );
  }

  return paymentDetails[0];
};

const addPaymentMethod = async (
  stripe: Stripe | null,
  stripeElements: StripeElements | null,
  identityState: IdentityState,
  setEditingCC: any,
  isUserPage: boolean
): Promise<PaymentMethod> => {
  const tokenResult = await getStripePaymentMethodToken(stripe, stripeElements);
  let paymentDetails: PaymentMethod[];
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/paymentMethod",
      "POST",
      tokenResult.token,
      isUserPage ? false : identityState
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages[0]);
    }

    setEditingCC(false);

    paymentDetails = responseData.filter(
      (paymentMethod: PaymentMethod) => paymentMethod.active
    );
  } catch (err) {
    throw new Error(`Unable to add payment method: ${err}`);
  }

  return paymentDetails[0];
};

async function updateOverageLimit(
  newDollarLimit: number,
  planDetails: PlanDetails,
  currentOverage: Overage,
  isUserPage: boolean,
  identityState: IdentityState,
  setEditingOverage?: any
): Promise<Overage> {
  const newOverageLimit: number = roundDownOverageBudget(
    newDollarLimit,
    planDetails
  );

  const updateOverageBody = {
    enabled: true,
    limitEnabled: true,
    limitDollars: newOverageLimit,
  };

  var newOverage = undefined;

  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/overage",
      "POST",
      updateOverageBody,
      isUserPage ? false : identityState
    );
    newOverage = await response.json();

    if (response.status !== 200) {
      throw new Error(newOverage.messages.join(". "));
    }

    if (setEditingOverage) {
      setEditingOverage(false);
    }
  } catch (err) {
    throw new Error(
      `Unable to update overage limit to $${newDollarLimit} : ${err}`
    );
  }

  return {
    ...currentOverage,
    enabled: newOverage.enabled,
    dollarsLimit: newOverage.limitDollars,
    creditsLimit: newOverage.limitCredits,
  };
}

const submitNewOrganisation = async (orgDetails: Organisation) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/v2/organisation",
      "POST",
      orgDetails
    );
    const newOrg = await response.json();

    if (response.status !== 200) {
      throw new Error(newOrg.messages.join(". "));
    }

    return newOrg;
  } catch (err) {
    throw new Error(
      `Unable to submit new organisation '${orgDetails.name}' : ${err}`
    );
  }
};

const editOrganisation = async (
  values: Organisation,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/v2/organisation",
      "PUT",
      values,
      identityState
    );
    const updatedOrganisation = await response.json();

    if (response.status !== 200) {
      throw new Error(updatedOrganisation.messages.join(". "));
    }

    return updatedOrganisation;
  } catch (err) {
    throw new Error(
      `Unable to update organisation details for '${values.name}' : ${err}`
    );
  }
};

const createRelease = async (
  values: CreateRelease,
  identityState: IdentityState
): Promise<Release> => {
  let values_ = { ...values, status: "staged" };

  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/admin/datasets/release",
      "POST",
      values_,
      identityState
    );
    const newRelease = await response.json();

    if (response.status !== 200) {
      throw new Error(newRelease.messages.join(". "));
    }

    return newRelease;
  } catch (err) {
    throw new Error(`Unable to stage a new data release: ${err}`);
  }
};

const draftRelease = async (
  values: CreateRelease,
  identityState: IdentityState
): Promise<Release> => {
  let values_ = { ...values, status: "draft" };

  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/admin/datasets/release",
      "POST",
      values_,
      identityState
    );
    const newRelease = await response.json();

    if (response.status !== 200) {
      throw new Error(newRelease.messages.join(". "));
    }

    return newRelease;
  } catch (err) {
    throw new Error(`Unable to draft a new draft data release: ${err}`);
  }
};

const changeReleaseStatus = async (
  release_id: string,
  status: ReleaseStatus,
  identityState: IdentityState
): Promise<Release> => {
  let value = { status: status };

  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/release/${release_id}`,
      "PUT",
      value,
      identityState
    );
    const newRelease = await response.json();

    if (response.status !== 200) {
      throw new Error(newRelease.messages.join(". "));
    }

    return newRelease;
  } catch (err) {
    throw new Error(
      `Unable to change release '${release_id}' status to '${status}' : ${err}`
    );
  }
};

const changeDatasetStatus = async (
  ids: string[],
  status: DatasetStatus,
  identityState: IdentityState
) => {
  let value = {
    ids: ids,
    status: status,
  };
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/manage`,
      "POST",
      value,
      identityState
    );
    const statusResponse = await response.json();

    if (response.status !== 200) {
      throw new Error(statusResponse.messages.join(". "));
    }

    return statusResponse;
  } catch (err) {
    throw new Error(
      `Unable to change '${ids.length}' dataset statuses to '${status}' : ${err}`
    );
  }
};

const deleteOrganisationInvitation = async (
  organisationApigeeDeveloperId: string,
  developerApigeeDeveloperId: string,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/v2/organisation/membership?orgId=${organisationApigeeDeveloperId}&devId=${encodeURIComponent(
        developerApigeeDeveloperId
      )}`,
      "DELETE",
      identityState
    );
    const deletedOrganisationInvitation = await response.json();

    if (response.status !== 200) {
      throw new Error(deletedOrganisationInvitation.messages.join(". "));
    }

    return deletedOrganisationInvitation;
  } catch (err) {
    throw new Error(
      `Unable to delete organisation invitation for developer '${developerApigeeDeveloperId}' in organisation '${organisationApigeeDeveloperId}' : ${err}`
    );
  }
};

const submitOrganisationInvitations = async (
  emails: string[],
  organisationId: string,
  identityState: IdentityState
) => {
  try {
    const alertStateList: AlertState[] = [];

    await Promise.all(
      emails.map((email) =>
        makeApiRequestPostToUrl(
          `${import.meta.env.VITE_DELIVERY_API_URL}`,
          "/v2/organisation/membership",
          "POST",
          {
            userEmail: email,
            organisationId: organisationId,
            invitationStatus: "PENDING",
          },
          identityState
        )
      )
    ).then((results) => {
      results.forEach(async (r, i) => {
        const res = await r.json();

        let message = res?.messages?.join(". ");
        let severity: AlertColor = "info";
        if (r.status !== 200) {
          severity = "error";
        } else {
          message = res.userExisted
            ? `Invitation to ${res.developerEmail} has been sent. Invited user is already registered with Geoscape.`
            : `Invitation to ${res.developerEmail} has been sent. Invited user does not exist in Geoscape. They may need to sign up.`;
          severity = "info";
        }
        alertStateList.push({
          apigeeDeveloperId: res.apigeeDeveloperId,
          developerEmail: res.developerEmail,
          developerName: res.developerName,
          message: message,
          severity: severity,
        });
      });
    });

    return alertStateList;
  } catch (err) {
    throw new Error(
      `Unable to submit organisation invitations for organisation '${organisationId}' to '${emails.length}' emails: ${err}`
    );
  }
};

const editApiKey = async (
  updatedValues: ApiKey,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/apiKeys/${encodeURIComponent(updatedValues.name)}`,
      "PUT",
      updatedValues,
      identityState
    );
    const updatedApiKey = await response.json();

    if (response.status !== 200) {
      throw new Error(updatedApiKey.messages.join(". "));
    }

    return updatedApiKey;
  } catch (err) {
    throw new Error(
      `Unable to update api key details for api key '${updatedValues.displayName}' : ${err}`
    );
  }
};

const deleteApiKey = async (
  apiKeyName: string,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/apiKeys/${encodeURIComponent(apiKeyName)}`,
      "DELETE",
      identityState
    );
    const deletedApiKey = await response.json();

    if (response.status !== 200) {
      throw new Error(deletedApiKey.messages.join(". "));
    }

    return deletedApiKey;
  } catch (err) {
    throw new Error(`Unable to delete api key '${apiKeyName}' : ${err}`);
  }
};

const deleteBatchJobs = async (
  jobs: string[],
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/delete`,
      "POST",
      jobs,
      identityState
    );
    const deletedJobs = await response.json();

    if (response.status !== 200) {
      throw new Error(deletedJobs.messages.join(". "));
    }

    return deletedJobs;
  } catch (err) {
    throw new Error(`Unable to delete '${jobs.length}' batch jobs: ${err}`);
  }
};

const createApiKey = async (
  apiKeyValues: ApiKey,
  identityState: IdentityState
) => {
  try {
    apiKeyValues.displayName = apiKeyValues.name;

    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/apiKeys`,
      "POST",
      apiKeyValues,
      identityState
    );
    const createdApiKey = await response.json();

    if (response.status !== 200) {
      throw new Error(createdApiKey.messages.join(". "));
    }

    createdApiKey.status = "approved";

    return createdApiKey;
  } catch (err) {
    throw new Error(
      `Unable to create api key '${apiKeyValues.displayName}': ${err}`
    );
  }
};

const updateSubscription = async (
  values: UpdateSubscription,
  identityState: IdentityState,
  isUser: boolean
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/subscriptions",
      "POST",
      values,
      isUser ? false : identityState
    );
    const updatedSubscription = await response.json();

    if (response.status !== 200) {
      throw new Error(updatedSubscription.messages.join(". "));
    }

    return updatedSubscription;
  } catch (err) {
    throw new Error(`Unable to update subscription: ${err}`);
  }
};

const cancelFutureSubscription = async (identityState: IdentityState) => {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/nextSubscription",
      "DELETE",
      identityState
    );
    const deletedFutureSubscription = await response.json();

    if (response.status !== 200) {
      throw new Error(deletedFutureSubscription.messages.join(". "));
    }

    return deletedFutureSubscription;
  } catch (err) {
    throw new Error(`Unable to delete subscription: ${err}`);
  }
};

const adminDeleteOrganisationMember = async (
  organisationId: string,
  userId: string,
  identityState: IdentityState
) => {
  try {
    const encodedOrgId = encodeURIComponent(organisationId);
    const encodedDevId = encodeURIComponent(userId);
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/organisation/members?organisationId=${encodedOrgId}&userId=${encodedDevId}`,
      "DELETE",
      identityState
    );
    const deletedOrganisationMember = await response.json();

    if (response.status !== 200) {
      throw new Error(deletedOrganisationMember.messages.join(". "));
    }

    return deletedOrganisationMember;
  } catch (err) {
    throw new Error(
      `Unable to delete member '${userId}' from the organisation '${organisationId}' : ${err}`
    );
  }
};
const userRejectInvitation = async (
  organisationId: string,
  identityState: IdentityState
): Promise<RejectedInvite> => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/v2/user/membership/${organisationId}`,
      "DELETE",
      {},
      identityState
    );
    const newMembership = await response.json();

    if (response.status !== 200) {
      throw new Error(newMembership.messages.join(". "));
    }

    return newMembership;
  } catch (err) {
    throw new Error(
      `Failed to accept invitation for org ${organisationId} - ${err}`
    );
  }
};

const userAcceptInvitation = async (
  organisationId: string,
  identityState: IdentityState
): Promise<UserMembership> => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/v2/user/membership",
      "POST",
      {
        organisationId: organisationId,
      },
      identityState
    );
    const newMembership = await response.json();

    if (response.status !== 200) {
      throw new Error(newMembership.messages.join(". "));
    }

    return newMembership;
  } catch (err) {
    throw new Error(
      `Failed to accept invitation for org ${organisationId} - ${err}`
    );
  }
};

const adminAddOrganisationMember = async (
  organisationId: string,
  emails: string[],
  identityState: IdentityState
) => {
  try {
    // Calls the api X times to invite X emails. Unlikely optimal, but makes it very simple
    const responses = await Promise.all(
      emails.map((email) =>
        makeApiRequestPostToUrl(
          `${import.meta.env.VITE_DELIVERY_API_URL}`,
          "/admin/organisation/members",
          "POST",
          {
            organisationId: organisationId,
            userEmail: email,
            invitationStatus: "PENDING",
          },
          identityState
        )
      )
    ).then((results) => Promise.all(results.map((result) => result.json())));
    return responses;
  } catch (err) {
    throw new Error(
      `Unable to add '${emails.length}' emails to the organisation as admin: ${err}`
    );
  }
};

const adminSetOrganisationStatus = async (
  organisationId: string,
  apigeeDeveloperStatus: ApigeeDeveloperStatus,
  identityState: IdentityState
): Promise<DeveloperStatus> => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/organisation/status/${organisationId}`,
      "PUT",
      {
        action: apigeeDeveloperStatus.action,
      },
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }
    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to change the developer's status as admin for account '${apigeeDeveloperStatus.apigeeDeveloperId}' to '${apigeeDeveloperStatus.action}' : ${err}`
    );
  }
};

const editAdminOrganisation = async (
  organisationId: string,
  values: AdminOrganisationDetail,
  identityState: IdentityState
): Promise<any> => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/organisation/${organisationId}`,
      "PUT",
      values,
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to edit the organisation's details as admin for organisation '${organisationId}' : ${err}`
    );
  }
};

const createAdminOrganisation = async (
  values: Organisation,
  identityState: IdentityState
): Promise<OrganisationRow> => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/organisation`,
      "POST",
      values,
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to create the organisation as admin for organisation: ${err}`
    );
  }
};

const editAdminAccount = async (
  accountId: string,
  values: AdminAccountDetails,
  identityState: IdentityState
): Promise<any> => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/accounts/${accountId}`,
      "PUT",
      values,
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to edit the account details as admin for account '${accountId}' : ${err}`
    );
  }
};

const deleteAdminUser = async (
  organisationId: string,
  identityState: IdentityState
): Promise<any> => {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/organisation/${organisationId}`,
      "DELETE",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to delete customer as admin for organisation '${organisationId}' : ${err}`
    );
  }
};

const adminDeleteAccount = async (
  accountId: string,
  identityState: IdentityState
): Promise<any> => {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/accounts/${accountId}`,
      "DELETE",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to delete account as admin for account:'${accountId}' : ${err}`
    );
  }
};

const adminDisableAccount = async (
  accountId: string,
  identityState: IdentityState
): Promise<any> => {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/accounts/${accountId}/disable`,
      "PUT",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to disable account as admin for account:'${accountId}' : ${err}`
    );
  }
};

const adminEnableAccount = async (
  accountId: string,
  identityState: IdentityState
): Promise<any> => {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/accounts/${accountId}/enable`,
      "PUT",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to enable account as admin for account:'${accountId}' : ${err}`
    );
  }
};

const deleteAdminUserMembership = async (
  organisationId: string,
  userMemberId: string,
  identityState: IdentityState
) => {
  try {
    const encodedOrgId = encodeURIComponent(organisationId);
    const encodedDevId = encodeURIComponent(userMemberId);
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/organisation/user/membership?organisationId=${encodedOrgId}&userMemberId=${encodedDevId}`,
      "DELETE",
      identityState
    );
    const deletedOrganisationMember = await response.json();

    if (response.status !== 200) {
      throw new Error(deletedOrganisationMember.messages.join(". "));
    }

    return deletedOrganisationMember;
  } catch (err) {
    throw new Error(
      `Unable to delete member '${userMemberId}' from the organisation '${organisationId}' : ${err}`
    );
  }
};

const editAdminUserSubscription = async (
  organisationId: string,
  values: SubmitEditAdminUserSubscription,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/subscriptions/${organisationId}`,
      "PUT",
      values,
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to edit subscription for user as admin for organisation '${organisationId}' : ${err}`
    );
  }
};

const createAdminUserApiKey = async (
  apiKeyValues: ApiKey,
  organisationId: string,
  identityState: IdentityState
) => {
  try {
    apiKeyValues.displayName = apiKeyValues.name;

    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/apiKeys?organisationId=${organisationId}`,
      "POST",
      apiKeyValues,
      identityState
    );
    const createdApiKey = await response.json();

    if (response.status !== 200) {
      throw new Error(createdApiKey.messages.join(". "));
    }

    createdApiKey.status = "approved";

    return createdApiKey;
  } catch (err) {
    throw new Error(
      `Unable to create api key for user as admin for organisation '${organisationId}' and api key '${apiKeyValues.displayName}' : ${err}`
    );
  }
};

const editAdminUserApiKey = async (
  apiKeyValues: ApiKey,
  organisationId: string,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/apiKeys/${encodeURIComponent(
        apiKeyValues.name
      )}?organisationId=${organisationId}`,
      "PUT",
      apiKeyValues,
      identityState
    );
    const updatedApiKey = await response.json();

    if (response.status !== 200) {
      throw new Error(updatedApiKey.messages.join(". "));
    }

    return updatedApiKey;
  } catch (err) {
    throw new Error(
      `Unable to edit api key for user as admin for organisation '${organisationId}' and api key '${apiKeyValues.displayName}' : ${err}`
    );
  }
};

const deleteAdminUserApiKey = async (
  apiKeyName: string,
  organisationId: string,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/apiKeys/${encodeURIComponent(
        apiKeyName
      )}?organisationId=${organisationId}`,
      "DELETE",
      identityState
    );
    const deletedApiKey = await response.json();

    if (response.status !== 200) {
      throw new Error(deletedApiKey.messages.join(". "));
    }

    return deletedApiKey;
  } catch (err) {
    throw new Error(
      `Unable to delete api key for user as admin for organisation '${organisationId}' and api key '${apiKeyName}' : ${err}`
    );
  }
};

const cancelAdminUserFutureSubscription = async (
  organisationId: string,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/subscriptions/nextSubscription/${organisationId}`,
      "DELETE",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to cancel subscription for user as admin for organisation '${organisationId}' : ${err}`
    );
  }
};

const customDatasetsCreate = async (
  customDatasets: CustomDatasetsPost[],
  apigeeDeveloperId: string,
  identityState: User
) => {
  customDatasets = customDatasets.map((customDataset: any) => ({
    ...customDataset,
    s3Path: `s3://${import.meta.env.VITE_CD_UPLOAD_BUCKET_NAME}/${
      customDataset.fileName
    }`,
    apigeeDeveloperId: apigeeDeveloperId,
    creator: identityState.firstName + " " + identityState.lastName,
  }));

  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/custom/`,
      "POST",
      customDatasets,
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to create '${customDatasets.length}' custom datasets for account '${apigeeDeveloperId}' : ${err}`
    );
  }
};

interface CustomDatasetsManageBody {
  status: string;
  ids: string[];
}

const manageCustomDatasetsStatus = async (
  body: CustomDatasetsManageBody,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/custom/manage`,
      "POST",
      body,
      identityState
    );
    const statusResponse = await response.json();

    if (response.status !== 200) {
      throw new Error(statusResponse.messages.join(". "));
    }

    return statusResponse;
  } catch (err) {
    throw new Error(
      `Unable to change the status for '${body.ids.length}' custom datasets to '${body.status}' : ${err}`
    );
  }
};

const deleteCustomDataset = async (
  customDatasetId: string,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/custom/${customDatasetId}`,
      "DELETE",
      identityState
    );
    const statusResponse = await response.json();

    if (response.status !== 200) {
      throw new Error(statusResponse.messages.join(". "));
    }

    return statusResponse;
  } catch (err) {
    throw new Error(
      `Unable to delete the custom dataset '${customDatasetId}' : ${err}`
    );
  }
};

const createBatchConfig = async (
  batchConfig: BatchConfig,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/config`,
      "POST",
      batchConfig,
      identityState
    );
    const statusResponse = await response.json();

    if (response.status !== 200) {
      throw new Error(statusResponse.messages.join(". "));
    }

    return statusResponse;
  } catch (err) {
    throw new Error(
      `Unable to save batch config '${batchConfig.batchInfo.batchId}' : ${err}`
    );
  }
};

const quoteBatchJob = async (batchId: string, identityState: IdentityState) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/${batchId}/quote`,
      "POST",
      {},
      identityState
    );
    const statusResponse = await response.json();

    if (response.status !== 200) {
      throw new Error(statusResponse.messages.join(". "));
    }

    return statusResponse;
  } catch (err) {
    throw new Error(`Unable to quote batch job '${batchId}' : ${err}`);
  }
};

const startBatchJob = async (
  batchId: string,
  name: string,
  totalRows: number,
  estimatedCost: number,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/${batchId}/start`,
      "POST",
      {
        name,
        totalRows,
        estimatedCost,
      },
      identityState
    );
    const statusResponse = await response.json();

    if (response.status !== 200) {
      throw new Error(statusResponse.messages.join(". "));
    }

    return statusResponse;
  } catch (err) {
    throw new Error(`Unable to start batch job '${batchId}' : ${err}`);
  }
};

const stopBatchJob = async (batchId: string, identityState: IdentityState) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/${batchId}/stop`,
      "POST",
      {},
      identityState
    );
    const statusResponse = await response.json();

    if (response.status !== 200) {
      throw new Error(statusResponse.messages.join(". "));
    }

    return statusResponse;
  } catch (err) {
    throw new Error(`Unable to stop batch job '${batchId}' : ${err}`);
  }
};

const createClipConfig = async (
  clipConfig: ClipConfiguration,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/clip/config`,
      "POST",
      clipConfig,
      identityState
    );
    const statusResponse = await response.json();

    if (response.status !== 200) {
      throw new Error(statusResponse.messages.join(". "));
    }

    return statusResponse;
  } catch (err) {
    throw new Error(`Unable to save clip '${clipConfig.id}' : ${err}`);
  }
};

const quoteClip = async (clipId: string, identityState: IdentityState) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/clip/${clipId}/quote`,
      "POST",
      {},
      identityState
    );
    const statusResponse = await response.json();

    if (response.status !== 200) {
      throw new Error(statusResponse.messages.join(". "));
    }

    return statusResponse;
  } catch (err) {
    throw new Error(`Unable to quote clip '${clipId}' : ${err}`);
  }
};

const extractClip = async (
  clipId: string,
  paymentMethod: "credits" | "invoice",
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/clip/${clipId}/extract`,
      "POST",
      {
        paymentMethod: paymentMethod,
      },
      identityState
    );
    const statusResponse = await response.json();

    if (response.status !== 200) {
      throw new Error(statusResponse.messages.join(". "));
    }

    return statusResponse;
  } catch (err) {
    throw new Error(`Unable to submit clip '${clipId}' : ${err}`);
  }
};

const invoiceClip = async (clipId: string, identityState: IdentityState) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/clip/${clipId}/invoice`,
      "POST",
      {},
      identityState
    );
    const statusResponse = await response.json();

    if (response.status !== 200) {
      throw new Error(statusResponse.messages.join(". "));
    }

    return statusResponse;
  } catch (err) {
    throw new Error(`Unable to invoice clip '${clipId}' : ${err}`);
  }
};

const validateCoupon = async (
  promoCode: string,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/coupon/${promoCode}`,
      "POST",
      {},
      identityState
    );
    const couponResponse = await response.json();

    if (response.status !== 200) {
      throw new Error(couponResponse.messages.join(". "));
    }

    return couponResponse;
  } catch (err) {
    throw new Error(`Unable to find coupon '${promoCode}' : ${err}`);
  }
};

const submitClipGeometryFile = async (
  s3Key: string,
  identityState: IdentityState
): Promise<GeoJSONFeatureCollection> => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/clip/processFileGeometry`,
      "POST",
      {
        s3Key: s3Key,
      },
      identityState
    );
    const geometryResponse = await response.json();

    if (response.status !== 200) {
      throw new Error(geometryResponse.messages.join(". "));
    }
    return geometryResponse;
  } catch (err) {
    throw new Error(`Unable to submit clip geometry file: ${err}`);
  }
};

const adminCreateUserNote = async (
  adminUserNote: AdminUserNote,
  identityState: IdentityState
): Promise<AdminUserNote> => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/note/${adminUserNote.accountId}`,
      "POST",
      adminUserNote,
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }
    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to create a note for the developer '${adminUserNote.accountId}' : ${err}`
    );
  }
};

const adminConfirmAccount = async (
  accountId: string,
  identityState: IdentityState
): Promise<any> => {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/accounts/${accountId}/confirm`,
      "PUT",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to confirm account as admin for account:'${accountId}' : ${err}`
    );
  }
};

const adminDemosGrantAccess = async (
  demoId: string,
  orgId: string,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/demos/${demoId}/${orgId}`,
      "PUT",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to grant ${demoId} demo access to orgId ${orgId}: ${err}`
    );
  }
};

const adminDemosRevokeAccess = async (
  demoId: string,
  orgId: string,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/demos/${demoId}/${orgId}`,
      "DELETE",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to revoke ${demoId} demo access to orgId ${orgId}: ${err}`
    );
  }
};

const adminEditDemoStatus = async (
  demoId: string,
  status: DemoStatus,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/demos/${demoId}`,
      "PUT",
      {
        "status": status,
      },
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to edit ${demoId} status to ${status}: ${err}`);
  }
};

export {
  addPaymentMethod,
  adminAddOrganisationMember,
  adminAddPaymentMethod,
  adminConfirmAccount,
  adminCreateUserNote,
  adminDeleteAccount,
  adminDeleteOrganisationMember,
  adminDemosGrantAccess,
  adminDemosRevokeAccess,
  adminDisableAccount,
  adminEditDemoStatus,
  adminEnableAccount,
  adminSetOrganisationStatus,
  cancelAdminUserFutureSubscription,
  cancelFutureSubscription,
  changeDatasetStatus,
  changeReleaseStatus,
  createAdminOrganisation,
  createAdminUserApiKey,
  createApiKey,
  createBatchConfig,
  createClipConfig,
  createRelease,
  customDatasetsCreate,
  deleteAdminUser,
  deleteAdminUserApiKey,
  deleteAdminUserMembership,
  deleteApiKey,
  deleteBatchJobs,
  deleteCustomDataset,
  deleteOrganisationInvitation,
  draftRelease,
  editAdminAccount,
  editAdminOrganisation,
  editAdminUserApiKey,
  editAdminUserSubscription,
  editApiKey,
  editOrganisation,
  extractClip,
  invoiceClip,
  manageCustomDatasetsStatus,
  quoteBatchJob,
  quoteClip,
  startBatchJob,
  stopBatchJob,
  submitClipGeometryFile,
  submitNewOrganisation,
  submitOrganisationInvitations,
  updateOverageLimit,
  updateSubscription,
  userAcceptInvitation,
  userRejectInvitation,
  validateCoupon,
};

export type { ReactQueryMutationError };

