import React, { useState } from "react";
import { Layer, Source } from "react-map-gl";
import { App2 } from "../cards/feature-card";
import { WelcomeCard } from "../cards/welcome-card";
import {
  ffeBuildingFFEStyle,
  ffeBuildingHeightStyle,
  ffeCadastreStyle,
  modelBuildingFFEStyle,
  modelBuildingHeightStyle,
  modelCadastreStyle,
  modelFloodStyle,
  realBuildingFFEStyle,
  realBuildingHeightStyle,
  realCadastreStyle,
  realFloodStyle,
} from "./styles";
import { FormState, Mode, StepDemoConfig } from "./types";
import { createCoordinates } from "./utils";

interface StyleSet {
  building_ffe: object;
  building_height: object;
  cadastre: object;
  flood: object;
}

type Layer = "building_ffe" | "building_height" | "cadastre" | "flood";

const styleForLayer = (mode: Mode, layer: Layer, selectedId: string | null) => {
  switch (mode) {
    case "ffe": {
      switch (layer) {
        case "building_ffe": {
          return ffeBuildingFFEStyle(selectedId);
        }
        case "cadastre": {
          return ffeCadastreStyle();
        }
        case "building_height": {
          return ffeBuildingHeightStyle(selectedId);
        }
        case "flood": {
          return realFloodStyle();
        }
      }
    }
    case "real": {
      switch (layer) {
        case "building_ffe": {
          return realBuildingFFEStyle(selectedId);
        }
        case "cadastre": {
          return realCadastreStyle();
        }
        case "building_height": {
          return realBuildingHeightStyle(selectedId);
        }
        case "flood": {
          return realFloodStyle();
        }
      }
    }
    default: {
      switch (layer) {
        case "building_ffe": {
          return modelBuildingFFEStyle(mode, selectedId);
        }
        case "cadastre": {
          return modelCadastreStyle(mode);
        }
        case "building_height": {
          return modelBuildingHeightStyle(mode, selectedId);
        }
        case "flood": {
          return modelFloodStyle();
        }
      }
    }
  }
};

// React components
interface MapLayerProps {
  geojsonPath: string;
  formState: FormState;
  layer_name: string;
  highlightedLayer: any;
  style: object;
  beforeId?: string;
}

export const MapLayer: React.FC<MapLayerProps> = React.memo(
  ({
    geojsonPath,
    formState,
    layer_name,
    highlightedLayer,
    style,
    beforeId,
  }) => {
    const [geoJsonData, setGeoJsonData] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);

    return (
      <Source type="geojson" data={geojsonPath}>
        {/* @ts-ignore */}
        <Layer {...style} id={layer_name} beforeId={beforeId} />

        {/* {!formState.extrude ? (
          <Layer {...highlightedLayer.highlightedLayerFlat} />
        ) : (
          <Layer {...highlightedLayer.highlightedLayer3D} />
        )} */}
      </Source>
    );
  }
);
// Step configurations
export const stepsDemos: Record<number, StepDemoConfig> = {
  1: {
    id: 1,
    component: WelcomeCard,
    mapConfig: { zoomLevel: 12 },
    data: {
      title: "Understanding Real-World Flood Risk in Ingham QLD",
    },
    coordinates: createCoordinates(-18.654493, 146.155588),
    layer: <></>,
  },
  2: {
    id: 2,
    component: App2,
    mapConfig: { zoomLevel: 16, minZoom: 10, pitch: 60, bearing: 30 },
    data: {},
    coordinates: createCoordinates(-18.65144, 146.16271),
    layer: <></>,
    geojsonLayers: [
      {
        layerName: "flood-cadastre",
        geojsonPath: "/demos/geojson/flood_hub_demo_cadastre_ingham.json",
        createStyle: (formState: FormState, selectedId: string | null) => {
          const { mode } = formState;
          return {
            id: "flood-cadastre",
            type: "fill-extrusion",
            layout: {
              visibility: mode == Mode.ffe ? "none" : "visible",
            },
            paint:
              mode == Mode.ffe
                ? {}
                : styleForLayer(mode, "cadastre", selectedId),
          };
        },
      },
      {
        layerName: "cadastre-layer",
        geojsonPath: "/demos/geojson/flood_hub_demo_cadastre_ingham.json",
        createStyle: (formState: FormState, selectedId: string | null) => {
          const { mode } = formState;
          return {
            id: "cadastre-layer",
            type: "line",
            layout: {
              visibility: mode == Mode.ffe ? "visible" : "none",
            },
            paint:
              mode == Mode.ffe
                ? styleForLayer(mode, "cadastre", selectedId)
                : {},
          };
        },
      },
      {
        layerName: "flood-building-ffe",
        geojsonPath: "/demos/geojson/flood_hub_demo_buildings_ingham-3.json",
        createStyle: (formState: FormState, selectedId: string | null) => {
          const { mode } = formState;
          return {
            id: "flood-buildings-ffe",
            type: "fill-extrusion",
            paint: styleForLayer(mode, "building_ffe", selectedId),
          };
        },
      },
      {
        layerName: "flood-building-height",
        geojsonPath: "/demos/geojson/flood_hub_demo_buildings_ingham-3.json",
        createStyle: (formState: FormState, selectedId: string | null) => {
          const { mode } = formState;
          return {
            id: "flood-building-height",
            type: "fill-extrusion",
            paint: styleForLayer(mode, "building_height", selectedId),
          };
        },
      },
      {
        layerName: "flood-level",
        geojsonPath: "/demos/geojson/flood_hub_demo_aoi_ingham.json",
        createStyle: (formState: FormState, selectedId: string | null) => {
          const { mode } = formState;
          return {
            id: "flood-level",
            type: "fill-extrusion",
            layout: {
              visibility: mode == Mode.ffe ? "none" : "visible",
            },
            paint: styleForLayer(mode, "flood", selectedId),
          };
        },
      },
    ],
  },
};
