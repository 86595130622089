import { WarningAmber } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { Attributes, StepComponentProps } from "../step-config-file/types";

interface InsightItem {
  label: string | any;
  value: string | number | any;
  uom?: string;
}

// Generic function to handle rendering of table based on insight type and data.
const renderInsightTable = <T extends InsightItem>(
  data: T[],
  title: string
) => (
  <>
    <Typography variant="h6" color={"textPrimary"} style={{ fontWeight: 505 }}>
      {title}
    </Typography>

    <Table size="small" sx={{ border: "none" }}>
      <TableBody>
        {data.map((item, index) => (
          <TableRow key={index}>
            <TableCell
              component="th"
              scope="row"
              sx={{ border: "none", padding: "0px" }}
            >
              <Typography variant="body1" sx={{ padding: "0px" }}>
                {item.label}
              </Typography>
            </TableCell>
            <TableCell sx={{ border: "none", padding: "0px" }} align="right">
              <Typography variant="body1" sx={{ padding: "0px" }}>
                {typeof item.value === "number"
                  ? Number.isInteger(item.value)
                    ? item.value
                    : item.value.toFixed(2)
                  : item.value}{" "}
                {item.uom ?? null}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </>
);

interface FeatureCardProps {
  address: string;
  featureDetails: InsightItem[];
  floodDetails: InsightItem[];
  headerOneTitle: string;
  headerTwoTitle: string;
  setCardOpen: any;
  style: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  address,
  featureDetails,
  floodDetails,
  headerOneTitle,
  headerTwoTitle,
  setCardOpen,
  style,
}) => {
  const initialPosition = {
    x: Math.min(window.innerWidth * 0.7, window.innerWidth - 700),
    y: window.innerHeight * 0.1,
  };
  const [position, setPosition] = useState(initialPosition);
  const nodeRef = useRef(null);
  const floodAlertThreshold = floodDetails.find(
    (x) => x.label === `${style} Year`
  )?.value;

  const shouldShowAlert = floodAlertThreshold > 0;

  return (
    <Draggable
      nodeRef={nodeRef}
      position={position}
      onStop={(e, data) => setPosition({ x: data.x, y: data.y })}
    >
      <Card
        ref={nodeRef}
        className={"docomdo"}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "400px",
        }}
      >
        <CardHeader
          sx={{
            border: "none",
            cursor: "move",
          }}
          title={address}
          action={
            <IconButton
              aria-label="close"
              onClick={() => {
                //   setExpanded(false);
                setCardOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent
          ref={nodeRef}
          sx={{
            paddingBottom: "0",
            width: "100%",
            margin: 0,
          }}
        >
          {/* Render tables using generic function */}
          {renderInsightTable(featureDetails, headerOneTitle)}

          {shouldShowAlert ? (
            <Alert
              variant="outlined"
              icon={<WarningAmber />}
              color="warning"
              sx={{
                marginTop: "10px",
              }}
            >
              At risk of {style} year flood
            </Alert>
          ) : (
            <Divider
              sx={{
                paddingTop: "10px",
              }}
            />
          )}
          <br></br>
          {renderInsightTable(floodDetails, headerTwoTitle)}
        </CardContent>
      </Card>
    </Draggable>
  );
};

interface CardContent {
  featureDetails: InsightItem[];
  floodDetails: InsightItem[];
  headerOne: string;
  headerTwo: string;
}

const contentForCard = (
  attributes: Attributes,
  type: "flood-building-height" | "flood-cadastre" | null
): CardContent => {
  if (!attributes) {
    return {
      featureDetails: [],
      floodDetails: [],
      headerOne: "",
      headerTwo: "",
    };
  }
  const properties = attributes.properties;

  if (type === "flood-building-height") {
    const featureDetails = [
      {
        label: "Ground Elevation",
        value: properties.ground_elevation,
        uom: "m",
      },
      {
        label: "First Floor Elevation",
        value: properties.first_floor_elevation,
        uom: "m",
      },
      {
        label: "Roof Height",
        value: properties.roof_height,
        uom: "m",
      },
      {
        label: "Estimated Levels",
        value: properties.estimated_levels,
      },
      {
        label: "Swimming Pool Adjacent",
        value: properties.swimming_pool_adjacent,
      },

      {
        label: "Solar Panels",
        value: properties.solar_panel,
      },

      {
        label: "Planning Zone",
        value: properties.planning_zone,
      },
      {
        label: "Building PID",
        value: properties.building_pid,
      },
      {
        label: "Area",
        value: properties.area,
        uom: "m²",
      },
      {
        label: "Basement",
        value: properties.basement,
      },
      {
        label: "Garage",
        value: properties.garage,
      },
    ];
    const floodDetails: InsightItem[] = [20, 50, 100, 200, 500, 1500].map(
      (x) => {
        return {
          label: `${x} Year`,
          value: properties[`River_RP${x}_mean`],
          uom: "m",
        };
      }
    );

    return {
      featureDetails: featureDetails,
      floodDetails: floodDetails,
      headerOne: `Primary Buildings Details`,
      headerTwo: "Flood Height",
    };
  }

  if (type === "flood-cadastre") {
    const featureDetails: InsightItem[] = [
      {
        label: "Parcel ID",
        value: properties.parcel_id,
      },
      {
        label: "Lot",
        value: properties.lot,
      },
      {
        label: "Plan",
        value: properties.plan,
      },
      {
        label: "Title Status",
        value: properties.title_status,
      },
      {
        label: "Parcel Type",
        value: properties.parcel_type,
      },
      {
        label: "Cadastre PID",
        value: properties.cadastre_pid,
      },
      {
        label: "Area",
        value: properties.area,
        uom: "m²",
      },
    ];
    const floodDetails: InsightItem[] = [20, 50, 100, 200, 500, 1500].map(
      (x) => {
        return {
          label: `${x} Year`,
          value: properties[`River_RP${x}_mean`],
          uom: "m",
        };
      }
    );
    return {
      featureDetails: featureDetails,
      floodDetails: floodDetails,
      headerOne: `Primary Cadastre Details`,
      headerTwo: "Flood height",
    };
  }
  return {
    featureDetails: [],
    floodDetails: [],
    headerOne: "",
    headerTwo: "",
  };
};

export const App2 = (props: StepComponentProps) => {
  //   @ts-ignore
  const { style } = props.formState;

  const [cardOpen, setCardOpen] = useState(false);
  const cardContent = contentForCard(
    props.attributes,
    props.selectedLayer as "flood-building-height" | "flood-cadastre" | null
  );
  const populatedAttributes = props.attributes
    ? Object.values(props.attributes.properties).length > 0
    : false;
  useEffect(() => {
    if (populatedAttributes && !cardOpen) {
      setCardOpen(true);
    } else if (!populatedAttributes) {
      setCardOpen(false);
    }
  }, [props.attributes]);

  if (props.attributes && cardOpen) {
    return (
      <FeatureCard
        address={props.attributes.properties.address}
        featureDetails={cardContent.featureDetails}
        floodDetails={cardContent.floodDetails}
        setCardOpen={setCardOpen}
        headerOneTitle={cardContent.headerOne}
        headerTwoTitle={cardContent.headerTwo}
        style={style}
      />
    );
  }

  return null;
};
export default FeatureCard;
