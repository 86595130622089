// React query error wrapper component

import { Alert, AlertTitle, Box } from "@mui/material";
import { ReactQueryMutationError } from "data/mutations";
import { ReactQueryQueryError } from "data/queries";
import _ from "lodash";
import { UseMutationResult, UseQueryResult } from "react-query";

interface ReactQueryErrorWrapperProps {
  queries?: UseQueryResult<any, unknown>[];
  mutations?: UseMutationResult<any, unknown, any, unknown>[];
}

export const ReactQueryErrorWrapper = (props: ReactQueryErrorWrapperProps) => {
  const isQueryError = _.some(props.queries?.map((query) => query.isError));
  const isMutationError = _.some(
    props.mutations?.map((mutation) => mutation.isError)
  );

  const isError = isQueryError || isMutationError;

  if (!isError) {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        marginBottom: isError ? "8px" : "0px",
      }}
    >
      {props.queries?.map((query) => {
        if (query.isError) {
          return (
            <Alert severity="error">
              <AlertTitle>
                Something has gone wrong, contact support if the issue persists.
              </AlertTitle>
              {(query.error as ReactQueryQueryError).message}
            </Alert>
          );
        }
      })}

      {props.mutations?.map((mutation) => {
        if (mutation.isError) {
          return (
            <Alert severity="error">
              <AlertTitle>
                Something has gone wrong, contact support if the issue persists.
              </AlertTitle>
              {(mutation.error as ReactQueryMutationError).message}
            </Alert>
          );
        }
      })}
    </Box>
  );
};
