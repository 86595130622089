import { Expression } from "mapbox-gl";
import { ColorScheme, Coordinates, FormState, MapConfig, Style } from "./types";

// Constants
const MAX_EXTRUSION_HEIGHT = 100;
const DEFAULT_ROOF_COLOR = "#86806F";
export const EXTRUSION_OPACITY = 0.8;

// Global min and max values for color scaling // This can be a Dictionary where the key is the Style
const GLOBAL_MIN = 1200;
const GLOBAL_MAX = 2000;

export const getExtrusionHeight = (
  style: Style,
  formState: FormState
): Expression => {
  return ["get", Style.RoofHeight];
};

export const getColorExpression = (
  style: Style,
  style_key_word?: string
): Expression => {
  if (style === Style.RoofColour) {
    return [
      "case",
      ["has", Style.RoofColour],
      ["get", Style.RoofColour],
      DEFAULT_ROOF_COLOR,
    ];
  }

  if (style === Style.Co2Annually) {
    return [
      "interpolate",
      ["linear"],
      ["get", style],
      GLOBAL_MIN,
      ColorScheme.Green,
      GLOBAL_MAX,
      ColorScheme.Red,
    ];
  }

  if (style === Style.Pool) {
    return [
      "case",
      ["==", ["get", style], "True"],
      "blue", // Green if Solar or Pool is true
      "#808080", // Grey if false
    ];
  }
  return [
    "case",
    ["==", ["get", style], "True"],
    "#00FF00", // Green if Solar or Pool is true
    "#808080", // Grey if false
  ];
};

// Helper functions for step configurations
export const createMapConfig = ({
  zoomLevel,
  minZoom,
  boundingBox,
}: MapConfig): MapConfig => ({
  zoomLevel, // use by flyTo
  minZoom,
  boundingBox,
});

export const createCoordinates = (
  latitude: number,
  longitude: number
): Coordinates => ({
  latitude,
  longitude,
});
