import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { FormState, Mode } from "../step-config-file/types";
import RadialPieChart from "./RadialChart";
// import { FormState } from "./types";

interface FormComponentProps {
  formState: FormState;
  onFormChange: (newState: Partial<FormState>) => void;
}

export const ActionCard: React.FC<FormComponentProps> = ({
  formState,
  onFormChange,
}) => {
  // This Action Card controls a state that modify the layer style
  const history = useHistory();
  const { showNewMessage } = useIntercom();
  const [selected, setSelected] = useState<string>(Mode.ffe);

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent<number | string>) => {
      const { name, value } = event.target;
      if (!value) {
        return;
      }
      onFormChange({ mode: value as Mode });
      setSelected(value as string);
    },
    [formState, onFormChange]
  );

  interface MenuItemData {
    key: string;
    value: string;
  }

  // MenuItemData This contains the properties that will be needed to be mapped from the Emisison metrics Geo-dataset
  const menuItems: MenuItemData[] = [
    { key: "First Floor Elevation", value: Mode.ffe },
    { key: "Feb. 2025 Event", value: Mode.real },
    { key: "20 Year", value: Mode.model_20 },
    { key: "50 Year", value: Mode.model_50 },
    { key: "100 Year", value: Mode.model_100 },
    { key: "200 Year", value: Mode.model_200 },
    { key: "500 Year", value: Mode.model_500 },
    { key: "1500 Year", value: Mode.model_1500 },
  ];

  return (
    <Card sx={{ borderRadius: 2, margin: "0px" }}>
      <RadialPieChart data_key={selected}></RadialPieChart>
      <CardContent
        sx={{
          margin: "0px",
          border: "px solid #ccc",
        }}
      >
        <FormControl fullWidth sx={{ mb: 1.5 }}>
          <InputLabel>Scenario</InputLabel>
          <Select
            fullWidth
            name="style"
            value={formState.mode}
            label="Style By"
            onChange={handleSelectChange}
            size="small"
          >
            {menuItems.map((s) => (
              <MenuItem value={s.value}>{s.key}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
};
