import { Box, Typography, useTheme } from "@mui/material";
import { CallToActionList } from "components/customer-journey/call-to-action";
import {
  ResendVerification,
  ResetPassword,
  SignIn,
  SignUp,
} from "components/user";
import GeoscapeBackgroundSvg from "icons/background.svg?react";
import GeoscapeDeveloperSvg from "icons/hub-logo-signIn.svg?react";
import { renderToStaticMarkup } from "react-dom/server";
import { Redirect, Route, Switch, useLocation } from "react-router";
import * as Routes from "routes";

import { CodeXml, CreditCard, Map, MapPinned } from "lucide-react";

export const SignedOut = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  /**
   * App in a signed out state.
   * A simple page layout with a form in center page depending on what route the anonymous user is on.
   */

  const prevLocation = useLocation();
  if (prevLocation.pathname != "/sign-in") {
    localStorage.setItem(`redirectPath`, prevLocation.pathname);
    localStorage.setItem(`query`, prevLocation.search);
  }

  const svgString = encodeURIComponent(
    renderToStaticMarkup(
      <GeoscapeBackgroundSvg fill={isDark ? "white" : "black"} />
    )
  );

  return (
    <>
      <div
        style={{
          // backgroundImage: `url('data:image/svg+xml;utf8, ${svgString}')`,
          backgroundRepeat: "repeat",
          backgroundSize: "cover",
          width: "100%",
          height: "100vh",
          position: "relative",
        }}
      >
        <Switch>
          <Route path="*">
            <Box
              alignItems="center"
              height="100%"
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "64px",
                backgroundColor: isDark ? "black" : "white",
              }}
              overflow="auto"
            >
              <Box
                display="flex"
                justifyContent="center"
                sx={{
                  backgroundColor: isDark ? "black" : "white",
                }}
              >
                {useLocation().pathname !== "/sign-up" && (
                  <GeoscapeDeveloperSvg
                    fill={isDark ? "white" : "black"}
                    style={{ width: "450px", height: "100px", padding: "0px" }}
                  />
                )}
              </Box>
              <div style={{ height: "32px" }}></div>
              <Box
                // display="flex"

                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: "32px",
                }}
              >
                <Box>
                  <Switch>
                    <Route exact path={Routes.signUp}>
                      <CallToActionList
                        callToActions={[
                          {
                            heading:
                              "Visualise the entire Geoscape product catalogue in a fast and responsive map interface.",
                            icon: <Map color={isDark ? "white" : "#3c6dc8"} />,
                          },
                          {
                            heading:
                              "Explore our range of address and building APIs.",
                            icon: (
                              <CodeXml color={isDark ? "white" : "#3c6dc8"} />
                            ),
                          },
                          {
                            heading:
                              "Download our datasets of your area of interest.",
                            icon: (
                              <MapPinned color={isDark ? "white" : "#3c6dc8"} />
                            ),
                          },
                          {
                            heading:
                              "Your gateway to empowered, comprehensive location enablement.",
                            icon: (
                              <CreditCard
                                color={isDark ? "white" : "#3c6dc8"}
                              />
                            ),
                          },
                        ]}
                      />
                    </Route>
                    <Route exact path={Routes.welcomeBack}>
                      <CallToActionList
                        callToActions={[
                          {
                            heading:
                              "Thank you for taking the time to migrate your login",
                            text: "Don’t worry if you have active keys; they will work even if you haven’t migrated your login.",
                            icon: false,
                          },
                          {
                            heading: "",
                            text: "As part of the migration, we're making sure we have the right details for you. Please complete the form on the right, and we will send you an email verification link.",
                            icon: false,
                          },
                          {
                            heading: "",
                            text: "You will then be able to log in to the new Geoscape Hub and manage your plan and keys.",
                            icon: false,
                          },
                        ]}
                      />
                    </Route>
                  </Switch>
                </Box>

                <Box>
                  <Switch>
                    <Route exact path={Routes.signIn}>
                      <SignIn />
                    </Route>
                    <Route exact path={"/"}>
                      <SignIn />
                    </Route>
                    <Route exact path={Routes.signUp}>
                      <SignUp />
                    </Route>
                    <Route exact path={Routes.resetPassword}>
                      <ResetPassword />
                    </Route>
                    <Route exact path={Routes.resendVerification}>
                      <ResendVerification />
                    </Route>
                    <Route path={Routes.dataCatalogue}>
                      <Redirect to={Routes.signUp} />
                    </Route>
                    <Route path="*">
                      <Redirect
                        to={`${Routes.signIn}?next=${prevLocation.pathname}`}
                      />
                    </Route>
                  </Switch>
                </Box>
              </Box>
            </Box>
          </Route>
        </Switch>
        <Box position={"absolute"} bottom={"0px"} sx={{ ml: 2 }}>
          <Typography>
            <sub>v2.0.0.{import.meta.env.VITE_BUILD_NO}</sub>
          </Typography>
        </Box>
      </div>
    </>
  );
};
