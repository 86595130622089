export const oziusTableData = (properties: {
  [key: string]: string | null;
}): { [key: string]: { [key: string]: string | null }[] } => ({
  // Land Cover
  landCover: [
    {
      landUse: "Waterbody",
      hectare: properties.landcover_waterbody,
      percentage: properties.landcover_waterbody_pct,
    },
    {
      landUse: "Forest",
      hectare: properties.landcover_forest,
      percentage: properties.landcover_forest_pct,
    },
    {
      landUse: "Grassland",
      hectare: properties.landcover_grassland,
      percentage: properties.landcover_grassland_pct,
    },
    {
      landUse: "Cropland",
      hectare: properties.landcover_cropland,
      percentage: properties.landcover_cropland_pct,
    },
    {
      landUse: "Woodland",
      hectare: properties.landcover_woodland,
      percentage: properties.landcover_woodland_pct,
    },
    {
      landUse: "Built Up",
      hectare: properties.landcover_builtup,
      percentage: properties.landcover_builtup_pct,
    },
    {
      landUse: "Bare Ground",
      hectare: properties.landcover_bare_ground,
      percentage: properties.landcover_bare_ground_pct,
    },
    {
      landUse: "Total Land Cover Area",
      hectare: properties.total_area,
      percentage: null,
    },
  ],
  // Land Use
  landUse: [
    {
      landUse: "Grazing Native Vegetation",
      hectare: properties.landuse_grazing_native_vegetation,
      percentage: properties.landuse_grazing_native_vegetation_pct,
    },
    {
      landUse: "Grazing Native Pasture",
      hectare: properties.landuse_grazing_native_pasture,
      percentage: properties.landuse_grazing_native_pasture_pct,
    },
    {
      landUse: "Grazing Sown Pasture",
      hectare: properties.landuse_grazing_sown_pasture,
      percentage: properties.landuse_grazing_sown_pasture_pct,
    },
    {
      landUse: "Total Land Use",
      hectare: properties.total_area,
      percentage: properties.landuse_pct,
    },
  ],
});

export const winterTableData = (properties: {
  [key: string]: string | null;
}): { [key: string]: { [key: string]: string | null }[] } => ({
  landCover: [
    {
      landUse: "Waterbody",
      hectare: properties.waterbody,
      percentage: properties.waterbody_pct,
    },
    {
      landUse: "Pasture/Fallow",
      hectare: properties.fallow,
      percentage: properties.fallow_pct,
    },
    {
      landUse: "Crop Land",
      hectare: properties.crop_land,
      percentage: properties.crop_land_pct,
    },
    {
      landUse: "Barren Land",
      hectare: properties["barren_land "],
      percentage: properties["barren_land _pct"],
    },
    {
      landUse: "Trees/Plantation",
      hectare: properties.trees_plantation,
      percentage: properties.trees_plantation_pct,
    },
    {
      landUse: "Range Land/Forest",
      hectare: properties.land_forest,
      percentage: properties.range_land_forest_pct,
    },
  ],
  cropping: [
    {
      landUse: "Cereals",
      hectare: properties.winter_cereals,
      percentage: properties.winter_cereals_pct,
    },
    {
      landUse: "Pulses",
      hectare: properties.winter_pulses,
      percentage: properties.winter_pulses_pct,
    },
    {
      landUse: "Oilseeds",
      hectare: properties.winter_oilseeds,
      percentage: properties.winter_oilseeds_pct,
    },
  ],
  harvest: [
    {
      landUse: "Cereals Harvest",
      hectare: properties.winter_cereals_harvest,
      percentage: null,
    },
    {
      landUse: "Pulses Harvest",
      hectare: properties.winter_pulses_harvest,
      percentage: null,
    },
    {
      landUse: "Oilseeds Harvest",
      hectare: properties.winter_oilseeds_harvest,
      percentage: null,
    },
  ],
  landUse: [
    {
      landUse: "Total Area",
      hectare: properties.area_ha,
      percentage: properties.total_pct,
    },
  ],
});
export const summerTableData = (properties: {
  [key: string]: string | null;
}): { [key: string]: { [key: string]: string | null }[] } => ({
  landCover: [
    {
      landUse: "Waterbody",
      hectare: properties.waterbody,
      percentage: properties.waterbody_pct,
    },
    {
      landUse: "Pasture/Fallow",
      hectare: properties.fallow,
      percentage: properties.fallow_pct,
    },
    {
      landUse: "Crop Land",
      hectare: properties.crop_land,
      percentage: properties.crop_land_pct,
    },
    {
      landUse: "Barren Land",
      hectare: properties["barren_land "],
      percentage: properties["barren_land _pct"],
    },
    {
      landUse: "Trees/Plantation",
      hectare: properties.trees_plantation,
      percentage: properties.trees_plantation_pct,
    },
    {
      landUse: "Range Land/Forest",
      hectare: properties.land_forest,
      percentage: properties.range_land_forest_pct,
    },
  ],
  cropping: [
    {
      landUse: "Cereals",
      hectare: properties.summer_cereals,
      percentage: properties.cereals_pct, // Summer has no prefix
    },
    {
      landUse: "Summer Fallow",
      hectare: properties.summer_fallow,
      percentage: properties.fallow_pct, // Summer has no prefix
    },
  ],
  harvest: [
    {
      landUse: "Cereal Harvest",
      hectare: properties.summer_cereals_harvest,
      percentage: null,
    },
  ],
  landUse: [
    {
      landUse: "Total Area",
      hectare: properties.area_ha,
      percentage: properties.total_pct,
    },
  ],
});
