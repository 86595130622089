export const signIn = "/sign-in";
export const signUp = "/sign-up";
export const resetPassword = "/reset-password";
export const resendVerification = "/resend-verification";

export const help = "/help";

export const billing = "/billing";
export const paymentMethod = "/billing/payment-method";
export const usageLimit = "/billing/usage-limit";

// DEVELOPER
export const developerDashboard = "/developer/dashboard";
// Developer - Usage
export const developerUsage = "/usage";
// Developer - keys
export const developerApiKeys = "/api-keys";
export const developerApiKeysEditKey = "/developer/api-keys/edit-key/:keyName";
export const developerApiKeysCreateKey = "/developer/api-keys/create-key";
export const developerApiDocs = "https://api-docs.geoscape.com.au/";

export const geoscapeDocumentation =
  "https://docs.geoscape.com.au/en/stable/index.html";

// DOCS
export const docsPage = "/documentation";
export const docsPreRelease =
  "https://docs.geoscape.com.au/en/stable/prerelease_reports.html";

export const docsApi = "https://api-docs.geoscape.com.au";
// DATA
export const dataDashboard = "/data/dashboard";
export const dataExplorer = "/explorer";
export const dataExplorerClip = "/explorer/clip";

//DEMOS
export const demosPage = "/demos";
export const emissionsMetric = "/demos/emissions-metric";
export const nabLandUse = "/demos/nab-land-use";
export const floodDataDemo = "/demos/flood-data-demo";
export const predictiveAddress = "/demos/predictive-address";
export const predictiveLocality = "/demos/predictive-locality";
export const easements = "/demos/easements";
export const propertyInsights = "/demos/property-insights";

export const dataCatalogue = "/catalogue";
export const dataCatalogueEntry = "/catalogue/:productName";
export const dataDownload = "/datasets/download";
export const dataCustomDownload = "/datasets/custom-download";
export const dataTailor = "/data/tailor";

export const notFound = "/dashboard";
export const errors = "/usage/errors";

export const welcomeBack = "/welcome-back";
// export const legal = "/legal"

// Geoscape Hub
export const hub = "/hub";

// Settings
export const settings = "/settings";
//  Organisation Settings
export const orgGeneralSettings = "/settings/organisation/general-settings";
export const orgMembers = "/settings/organisation/members";
export const orgSubscription = "/settings/organisation/subscription";
export const orgOverage = "/settings/organisation/overage";
export const orgReceipts = "/settings/organisation/receipts";
export const orgBillingDetails = "/settings/organisation/billing-details";
export const orgManageSubscription =
  "/settings/organisation/manage-subscription";

//  User Settings
export const userGeneralSettings = "/settings/user/general-settings";
export const invitations = "/settings/user/pending-invitations";
export const userPendingInvitations = "/settings/user/pending-invitations";
export const userCreateOrganisation = "/settings/user/create-organisation";
export const userSubscription = "/settings/user/subscription";
export const userOverage = "/settings/user/overage";
export const userReceipts = "/settings/user/receipts";
export const userBillingDetails = "/settings/user/billing-details";
export const userManageSubscription = "/settings/user/manage-subscription";
export const legal = "/legal";
export const userSecurity = "/settings/user/security";

// Admin
export const admin = "/admin";
export const adminDashboard = "/admin/dashboard";

// Admin Org Management
export const adminOrganisations = "/admin/organisations";
export const adminOrganisationDetails =
  "/admin/organisations/:organisationId/:tab";

export const adminOrgMembers = "/admin/organisation/:organisationId/members";
export const adminApps = "/admin/organisations/:organisationId/apps";

export const adminUser = "/admin/management/user";

export const adminCreateApiKey =
  "/admin/organisations/:organisationId/apps/create";

export const adminEditApiKey =
  "/admin/organisations/:organisationId/apps/:keyName";

// Admin User Management
export const adminUsers = "/admin/users";
export const adminUserDetails = "/admin/users/:accountId";

// Admin Release Management
export const adminReleases = "/admin/releases";
export const adminCreateRelease = "/admin/release-management/create-release";
export const adminEditRelease =
  "/admin/release-management/edit-release/:releaseKey";
export const adminReleaseDatasets =
  "/admin/release-management/edit-release/:releaseKey/datasets";

// Admin Reporting
export const adminClipReporting = "/admin/clip-reporting";

export const adminDatasets = "/admin/release-management/datasets";
export const adminMergeAccounts = "/admin/management/merge-accounts";
export const adminMigrateKeys = "/admin/management/migrate-keys";

// Admin Reporting
export const adminCustomerUsage = "/admin/reporting/customer-usage";
export const adminOnDemand = "/admin/reporting/on-demand";
export const adminReceipts = "/admin/reporting/receipts";
export const adminBillingDetails = "/admin/reporting/billing-details";

// Admin Custom Datasets
export const adminViewCustomDatasets = "/admin/custom-datasets";

// Admin Demos
export const adminDemoManagement = "/admin/demo-management";
export const adminEditDemo = "/admin/demo-management/:adminDemoId";

// Endpoint for testing sentry error handling
export const testError = "/test/error";

// Batch
export const batchDashboard = "/batch/dashboard";
export const batchBatches = "/batch";
export const batchCreateBatch = "/batch/create";
