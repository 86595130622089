import { useState } from "react";
import { Attributes, DemoStep } from "../step-config-file/types";

const initialValues: Attributes[] = [
  {
    geometry: {},
    properties: {},
    layer: {},
    source: "",
  },
];

export const useNabLandUseState = () => {
  const [demoStep, setDemoStep] = useState<DemoStep>(1);
  const [attributes, setAttributes] = useState<Attributes[]>(initialValues);
  const [dynamicZoom, setDynamicZoom] = useState(4);
  const [markerPosition, setMarkerPosition] = useState({
    longitude: 0,
    latitude: 0,
  });
  const [markerShow, setShowMarker] = useState(false);

  return {
    demoStep,
    attributes,
    dynamicZoom,
    markerPosition,
    markerShow,
    setDemoStep,
    setAttributes,
    setDynamicZoom,
    setMarkerPosition,
    setShowMarker,
  };
};
