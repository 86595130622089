import { Add, Cancel } from "@mui/icons-material";
import { Box, Button, IconButton, TextField } from "@mui/material";
import ConfirmationModal from "components/modals/confirmation-modal";
import { Dispatch, SetStateAction, useState } from "react";
import { object, string } from "yup";

interface MemberInvitationsProps {
    isOpen: boolean;
    isLoading: boolean;
    isError?: boolean;
    handleClose: () => void;
    handleSubmit: (itemList: string []) => void;
    items?: string[];
}

export const invitationValidator = object({
    email: string()
      .email("Enter a valid email")
      .required("Email is required"),
});

const addAnotherItem = (
    itemList: string [],
    setItemList: Dispatch<SetStateAction<string[]>>
) => {
    if(itemList.length < 5)
        setItemList([...itemList, ''])
}

const removeItem = (
    item: string,
    itemList: string [],
    setItemList: Dispatch<SetStateAction<string []>>
) => {
    if(itemList.length === 1) {
        return;
    }
    const nextList = [...itemList];
    const i: number = nextList.indexOf(item);
    if (i >= 0) {
        nextList.splice(i, 1)
        setItemList(nextList);
    }
}

const hasDuplicates = (itemList: string []):boolean => {
    return (new Set(itemList)).size !== itemList.length;
}

const isErrorItem = (item: string): boolean => {
  if (item) {
    const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return !emailRegex.test(item);
  }
  return true;
};

const isErrorList = (itemList: string[]) => {
  return itemList.some((item) => isErrorItem(item)) || hasDuplicates(itemList);
};

const handleChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  index: number,
  itemList: string[],
  setItemList: Dispatch<SetStateAction<string[]>>
) => {
  const nextList = [...itemList];
  nextList[index] = event.target.value;
  setItemList(nextList);
};

const handleClose = (
  setItemList: Dispatch<SetStateAction<string[]>>,
  handleCloseProp: () => void
) => {
  setItemList([""]);
  handleCloseProp();
};

export const MemberInvitationModal = (props: MemberInvitationsProps) => {
  const [itemList, setItemList] = useState<string[]>([""]);

  return (
    <ConfirmationModal
      isLoading={props.isLoading}
      isError={props.isError}
      open={props.isOpen}
      header={"Invite New Members"}
      body={"Invite new members to join your organisation."}
      leftButtonText={"Send Invite"}
      leftButtonColor="primary"
      rightButtonText={"Cancel"}
      rightButtonColor="warning"
      handleClose={() => handleClose(setItemList, props.handleClose)}
      handleLeftButton={() => {
        props.handleSubmit(itemList);
        setItemList([""]);
      }}
      handleRightButton={() => handleClose(setItemList, props.handleClose)}
      leftButtonDisabled={isErrorList(itemList) || props.isLoading}
      rightButtonDisabled={props.isLoading}
    >
      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "5px",
        }}
      >
        {itemList.map((item, index) => (
          <TextField
            id={`email-${index}`}
            key={index}
            value={item}
            size="small"
            fullWidth
            type="email"
            variant="outlined"
            error={isErrorItem(item)}
            helperText={isErrorItem(item) ? "Please enter a valid email" : " "}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(event, index, itemList, setItemList)
            }
            InputProps={{
              endAdornment: (
                <IconButton
                  disabled={itemList.length <= 1}
                  onClick={() => removeItem(item, itemList, setItemList)}
                  edge="end"
                  color="primary"
                >
                  <Cancel />
                </IconButton>
              ),
            }}
          />
        ))}
        <Button
          disabled={
            itemList[itemList.length - 1] === "" || itemList.length >= 5
          }
          startIcon={<Add />}
          onClick={() => addAnotherItem(itemList, setItemList)}
        >
          Add Another
        </Button>
      </Box>
    </ConfirmationModal>
  );
};

