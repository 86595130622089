import React, { useMemo, useState } from "react";
import { Layer, LayerProps, Source } from "react-map-gl";
import { NabLandUseComponent } from "../cards/feature-card";
import { FormState, StepDemoConfig } from "./types";
import {
  EXTRUSION_OPACITY,
  createCoordinates,
  createMapConfig,
  getColorExpression,
  getExtrusionHeight,
} from "./utils";

const createLayerStyle = (formState: FormState, layer_name: string) => {
  const { extrude, style, style_key_word } = formState;

  return {
    id: layer_name,
    type: "fill-extrusion",
    paint: {
      "fill-extrusion-color": getColorExpression(style, style_key_word),
      "fill-extrusion-height": extrude
        ? getExtrusionHeight(style, formState)
        : 0,
      "fill-extrusion-opacity": EXTRUSION_OPACITY,
    },
  };
};

// React components
interface MapLayerProps {
  secondLayer?: boolean;
  geojsonPath: string;
  formState: FormState;
  layer_name: string;
  layer_name_source_two?: string;
  highlightedLayer: any;
}

export const MapLayer: React.FC<MapLayerProps> = React.memo(
  ({
    secondLayer,
    geojsonPath,
    formState,
    layer_name,
    layer_name_source_two,
  }) => {
    const layerStyle = useMemo(
      () => createLayerStyle(formState, layer_name),
      [formState, layer_name]
    );

    // highlightedLayer = !formState.extrude
    //   ? highlightedLayer.highlightedLayerFlat
    //   : highlightedLayer.highlightedLayer3D;

    // const [geoJsonData, setGeoJsonData] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const geoJsonData = geojsonPath;
    // useEffect(() => {
    //   const fetchAndDecompress = async () => {
    //     try {
    //       const response = await fetch(geojsonPath);

    //       if (!response.ok) {
    //         throw new Error(`HTTP error! status: ${response.status}`);
    //       }

    //       const buffer = await response.arrayBuffer();

    //       let jsonData;

    //       // If it's gzip, decompress it
    //       const decompressed = pako.ungzip(new Uint8Array(buffer), {
    //         to: "string",
    //       });
    //       jsonData = JSON.parse(decompressed);

    //       setGeoJsonData(jsonData);
    //       setError(null);
    //     } catch (error) {
    //       console.error("Error fetching or processing GeoJSON:", error);
    //       // @ts-ignore
    //       setError(`Error: ${error.message}`);
    //     }
    //   };

    //   if (geojsonPath) {
    //     fetchAndDecompress();
    //   }
    // }, [geojsonPath]);

    if (error) {
      return <div>Error loading map data: {error}</div>;
    }

    if (!geoJsonData) {
      return <div>Loading map data...</div>;
    }

    const outlineLayer: LayerProps = {
      id: "polygon-outline",
      type: "line",
      paint: {
        "line-color": "#0056b3", // Darker blue outline
        "line-width": 2,
      },
    };
    const polygonLayer: LayerProps = {
      id: layer_name,
      type: "fill",
      paint: {
        "fill-color": "red", // Blue fill
        "fill-opacity": 0.0, // 40% transparency
      },
    };

    const polygonLayer2: LayerProps = {
      id: layer_name_source_two,
      type: "fill",
      paint: {
        "fill-color": "#0080ff", // Blue fill
        "fill-opacity": 0.2, // 40% transparency
      },
    };
    return (
      <>
        <Source type="geojson" data={geoJsonData}>
          {/* @ts-ignore */}
          {secondLayer ? (
            <Layer {...polygonLayer2} />
          ) : (
            <Layer {...polygonLayer} />
          )}

          <Layer {...outlineLayer} />
        </Source>
      </>
    );
  }
);

// Step configurations
export const stepsDemos: Record<number, StepDemoConfig> = {
  1: {
    id: 1,
    component: NabLandUseComponent,
    mapConfig: createMapConfig({
      zoomLevel: 16,
      // minZoom: 2,
      // boundingBox: [133.7004, -24.9681, 134.3354, -24.3529], // I expand the bounding box in 10km each side
    }),
    data: {},
    coordinates: createCoordinates(-24.660497665405273, 134.01792907714844),
    layer: <></>,
    layer_name: "land-use-layer-satsure",
    layer_name_source_two: "land-use-layer-ozius",
    geojsonPath: "/demos/geojson/agri_satsure_wgs84.geojson",
    geojsonPath_source_two: "/demos/geojson/agri_ozius_wgs84.geojson",
  },
};
