import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { StepComponentProps } from "../step-config-file/types";

import { Box, Chip, Paper, TableContainer, TableHead } from "@mui/material";

import { styled } from "@mui/material/styles";
import { oziusTableData, summerTableData, winterTableData } from "./data";

const NoBorderTable = styled(Table)({
  "& .MuiTableCell-root, & .MuiTableRow-root": {
    borderBottom: "none",
  },
});

// Types

interface ParcelCardProps {
  properties_layers: any;
  setIsOpen: (value: boolean) => void;
}

// Helper Components
const SeasonChip: React.FC<{
  label: string;
  isActive: boolean;
  onClick: () => void;
}> = ({ label, isActive, onClick }) => (
  <Chip
    label={label}
    onClick={onClick}
    variant="outlined"
    color="primary"
    sx={{
      margin: "0 4px",
      backgroundColor: isActive ? "primary.main" : "",
      color: isActive ? "white" : "",
      cursor: "pointer",
      "&:hover": {
        color: isActive ? "primary.main" : "primary",
        //   backgroundColor: "red",
      },
    }}
  />
);

// Main Component
const ParcelCard: React.FC<ParcelCardProps> = ({
  properties_layers,
  setIsOpen: setIsOpen,
}) => {
  const [activeDataset, setActiveDataset] = useState("Satsure - Summer");

  const dataset = ["Satsure - Summer", "Satsure - Winter", "Ozius"];

  const handleSeasonChange = (dataset: string) => {
    setActiveDataset(dataset);
  };

  const dataSatsure = properties_layers.find(
    (item: { layer: { id: string } }) =>
      item.layer.id === "land-use-layer-satsure"
  );

  const dataOzius = properties_layers.find(
    (item: { layer: { id: string } }) =>
      item.layer.id === "land-use-layer-ozius"
  );

  const tableData =
    activeDataset === "Satsure - Winter"
      ? winterTableData(dataSatsure.properties)
      : activeDataset === "Satsure - Summer"
      ? summerTableData(dataSatsure.properties)
      : oziusTableData(dataOzius.properties);

  return (
    <>
      <CardHeader
        sx={{
          border: "none",
          cursor: "move",
          padding: 0,
        }}
        title={properties_layers[0].properties.parcel_id}
        subheader={`${properties_layers[0].properties.cadastre_pid} - ${properties_layers[0].properties.cadastre_polygon_pid}`}
        action={
          <IconButton
            aria-label="close"
            onClick={() => {
              //   setExpanded(false);
              setIsOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      <Box sx={{ padding: "16px", paddingLeft: "8px" }}>
        {dataset.map((season: string) => (
          <SeasonChip
            key={season}
            label={season}
            isActive={season === activeDataset}
            onClick={() => handleSeasonChange(season)}
          />
        ))}
      </Box>

      <Typography
        variant="h6"
        sx={{
          paddingTop: "8px",
          paddingBottom: "8px",
          paddingLeft: "16px",
        }}
      >
        {activeDataset}
      </Typography>

      <LandDataTable tableData={tableData} activeDataset={activeDataset} />
      <></>
    </>
  );
};

// Types
interface LandEntry {
  landUse: string;
  hectare: number;
  percentage: number | null;
}

interface TableData {
  landUse?: LandEntry[];
  landCover?: LandEntry[];
  cropping?: LandEntry[];
  harvest?: LandEntry[];
}

interface TableSectionProps {
  title: string;
  data?: LandEntry[];
  showPercentage?: boolean;
}

const StyledTableCell = styled(TableCell)(({ width, align }) => ({
  width: width,
  textAlign: align,
  fontWeight: "bold",
}));

// Reusable Table Section Component
const TableSection: React.FC<TableSectionProps> = ({
  title,
  data,
  showPercentage = true,
}) => {
  const formatNumber = (value: number | undefined): string => {
    if (value === undefined) return "0.00"; // Handle undefined or null values gracefully.
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  if (!data?.length) return null;

  return (
    <>
      <TableHead>
        <TableRow>
          <StyledTableCell width="50%" align="left">
            {title}
          </StyledTableCell>
          <StyledTableCell width="30%" align="left">
            Hectare
          </StyledTableCell>
          {showPercentage && (
            <StyledTableCell width="20%" align="right">
              Percentage
            </StyledTableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((entry, index) => (
          <TableRow key={`${title}-${index}`}>
            <TableCell sx={{ width: "50%", textAlign: "left" }}>
              {entry.landUse}
            </TableCell>
            <TableCell sx={{ width: "30%", textAlign: "left" }}>
              {formatNumber(entry.hectare)}
            </TableCell>
            {showPercentage && (
              <TableCell sx={{ width: "20%", textAlign: "right" }}>
                {entry.landUse == "Total Land Cover Area" ||
                entry.landUse == "Total Land Use"
                  ? ""
                  : entry.percentage != null
                  ? `${entry.percentage.toFixed(2)}%`
                  : "0.00%"}
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </>
  );
};

interface LandDataTableProps {
  tableData: TableData;
  activeDataset: string;
}

// Main Component
export const LandDataTable: React.FC<LandDataTableProps> = ({
  tableData,
  activeDataset,
}) => {
  return (
    <TableContainer component={Paper} sx={{ width: 418, border: "none" }}>
      <NoBorderTable size="small">
        <TableSection
          title="Land Use"
          data={tableData.landUse}
          showPercentage={activeDataset == "Ozius"}
        />
        <TableSection title="Land Cover" data={tableData.landCover} />
        {tableData.harvest && (
          <>
            <TableSection title="Cropping" data={tableData.cropping} />
            <TableSection
              title="Harvest"
              data={tableData.harvest}
              showPercentage={false}
            />
          </>
        )}
      </NoBorderTable>
    </TableContainer>
  );
};

// Generic function to handle rendering of table based on insight type and data.

export const NabLandUseComponent = (props: StepComponentProps) => {
  const initialPosition = {
    x: Math.min(window.innerWidth * 0.7, window.innerWidth - 700),
    y: window.innerHeight * 0.1,
  };
  const [position, setPosition] = useState(initialPosition);
  const nodeRef = useRef(null);

  const attributes = props.attributes ?? [];
  const firstAttribute = attributes[0] ?? {
    geometry: {},
    properties: {},
    layer: {},
    source: "",
  };

  const { layer_name } = props;

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (firstAttribute.layer?.id === layer_name) {
      setIsOpen(true);
    } else setIsOpen(false);
  }, [firstAttribute.layer?.id, layer_name, firstAttribute.properties]);

  if (firstAttribute.layer?.id === layer_name && isOpen) {
    return (
      <Draggable
        nodeRef={nodeRef}
        position={position}
        onStop={(e, data) => setPosition({ x: data.x, y: data.y })}
      >
        <Card
          ref={nodeRef}
          className={"docomdo"}
          sx={{
            display: "flex",
            width: "450px",

            paddingBottom: "0px",
          }}
        >
          <CardContent
            ref={nodeRef}
            sx={{
              paddingBottom: "0",
              margin: 0,
            }}
          >
            <ParcelCard properties_layers={attributes} setIsOpen={setIsOpen} />
          </CardContent>
        </Card>
      </Draggable>
    );
  }

  return <></>;
};
