import type { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { CreateOrganisationBody } from "components/user/sign-in/onboarding-wizard";
import { UserMembership } from "data/models";
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { IntercomProps } from "react-use-intercom";
import { Either, left, right } from "utils";
import { makeApiRequestPostToUrl, makeApiRequestToUrl } from "utils/api-client";
import { OrganisationRow } from "./models";

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  isAdmin: boolean;
  organisations: Organisation[];
  cognitoSub: string; // the OAUTH2.0 cognito subject
  cognitoUser: CognitoUser;
  onboarding: boolean;
  onboardingComplete: string;
}

export interface Organisation {
  name: string;
  organisationId: string;
  active: boolean;
  abn?: string;
}

// represents the state of identify of a user using the portal, who they are and what they're acting as
export type IdentityState = User | false | "loading";

export const GetUserIfLoggedIn = (
  identityState: IdentityState
): User | false => {
  if (identityState === "loading" || identityState === false) {
    return false;
  }
  return identityState;
};

export const isAdminIdentity = (identityState: IdentityState): boolean => {
  const user = GetUserIfLoggedIn(identityState);
  if (user !== false) {
    return user.isAdmin;
  }
  return false;
};

export const UserContext = createContext<IdentityState>(false);

export const switchedOrganisationCookieName = "switched-organisation";

export function useIdentity(): [
  IdentityState,
  Dispatch<SetStateAction<IdentityState>>
] {
  // @ts-ignore: might be able to fix this later, need to more understand context typing (could be createContext<[S, D?]>)
  const [userState, setUserState] = useContext(UserContext) as [
    IdentityState,
    Dispatch<SetStateAction<IdentityState>>
  ];

  const [cookies, setCookies, removeCookie] = useCookies([
    switchedOrganisationCookieName,
  ]);

  const user = GetUserIfLoggedIn(userState);

  const activeOrg = user
    ? user.organisations.find((x) => x.active === true)
    : null;

  useEffect(() => {
    if (activeOrg) {
      setCookies(switchedOrganisationCookieName, activeOrg, {
        path: "/",
      });
    } else {
      removeCookie(switchedOrganisationCookieName);
    }
  }, [activeOrg]);

  return [userState, setUserState];
}

// need figure out what type children is meant to be and assign it. Node? ChildNode? ReactChildren? Element? WHAT ARE YOU?!
export const IdentityProvider = (children: any) => {
  const [identityState, setIdentityState] = useState<IdentityState>("loading");
  const [cookies] = useCookies([switchedOrganisationCookieName]);

  const organisationCookie: Organisation | undefined =
    cookies[switchedOrganisationCookieName];

  const detectSignedInUser = async (): Promise<void> => {
    const signedInUser = await getSignedInUser(organisationCookie);
    if (signedInUser.state === "left") {
      setIdentityState(signedInUser.value);
    } else {
      setIdentityState(false);
    }
  };

  // Runs once on load, to detect if they are already signed in (has cognito cookies)
  useEffect(() => {
    detectSignedInUser();
  }, []);

  const value = React.useMemo(
    () => [identityState, setIdentityState],
    [identityState]
  );

  return <UserContext.Provider value={value} {...children} />;
};

export type Props = {
  children: ReactNode;
  context: IdentityState;
};

const convertToUser = (
  cognitoUser: CognitoUser,
  organisations: Organisation[],
  savedOrganisation?: Organisation
): User => {
  const dynamicUser = cognitoUser as any;
  const isAdmin =
    dynamicUser.signInUserSession.accessToken.payload.hasOwnProperty(
      "cognito:groups"
    ) &&
    dynamicUser.signInUserSession.accessToken.payload["cognito:groups"][0] ===
      "admin";

  const user = {
    firstName: dynamicUser.attributes.given_name,
    lastName: dynamicUser.attributes.family_name,
    email: dynamicUser.attributes.email,
    cognitoSub: dynamicUser.attributes.sub,
    isAdmin: isAdmin,
    organisations:
      organisations.length === 0
        ? []
        : setActiveOrganisation(organisations, savedOrganisation),
    cognitoUser: dynamicUser,
    onboarding: organisations.length === 0,
    onboardingComplete: dynamicUser.attributes["custom:onboardingComplete"],
  };

  return user;
};

export async function getSignedInUser(
  organisation?: Organisation
): Promise<Either<User, Error>> {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const organisationsResponse = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/v2/user/membership",
      "GET"
    );
    const userInvitation: UserMembership[] = await organisationsResponse.json();

    const organisations: Organisation[] = userInvitation
      .filter((o) => o.status === "Accepted")
      .map((o) => {
        return {
          name: o.organisationName,
          organisationId: o.organisationId,
          active: false,
        };
      });
    return left(convertToUser(cognitoUser, organisations, organisation));
  } catch (error: any) {
    return right(error);
  }
}

export async function isAdmin() {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const admin =
    cognitoUser.signInUserSession.accessToken.payload["cognito:groups"] &&
    cognitoUser.signInUserSession.accessToken.payload["cognito:groups"][0] ===
      "admin";

  return admin;
}

export const signUpUser = async (
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  recaptchaToken: string
): Promise<Either<boolean, Error>> => {
  try {
    await Auth.signUp({
      username: email.toLowerCase(),
      password: password,
      attributes: {
        given_name: firstName,
        family_name: lastName,
        "custom:dev_t_and_c": String(true),
      },
      validationData: {
        recaptcha_token: recaptchaToken,
      },
    });
    return left(true);
  } catch (error: any) {
    return right(error);
  }
};

export const convertToHubUser = async (
  cognitoUser: CognitoUser
): Promise<User> => {
  const userInvitationResponse = await makeApiRequestToUrl(
    `${import.meta.env.VITE_DELIVERY_API_URL}`,
    "/v2/user/membership",
    "GET"
  );

  const userInvitations: UserMembership[] = await userInvitationResponse.json();

  const organisations = userInvitations
    .filter((o) => o.status === "Accepted")
    .map((o) => {
      return {
        name: o.organisationName,
        organisationId: o.organisationId,
        active: false,
      };
    });

  // const organisations = [];
  return convertToUser(cognitoUser, organisations);
};

export const userCreateOrganisation = async (
  createOrg: CreateOrganisationBody
): Promise<OrganisationRow> => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/v2/organisation`,
      "POST",
      createOrg
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Failed to create organisation: ${err}`);
  }
};

export const getOrganisations = async (
  identity: IdentityState
): Promise<UserMembership[]> => {
  const getOrganisation = await makeApiRequestToUrl(
    `${import.meta.env.VITE_DELIVERY_API_URL}`,
    `/v2/user/membership?byEmail=true`,
    "GET",
    identity
  );

  const userOrganisation = await getOrganisation.json();

  return userOrganisation;
};

export const signOut = async (
  removeCookie: (
    name: typeof switchedOrganisationCookieName,
    options?: any
  ) => void
): Promise<Either<boolean, Error>> => {
  try {
    await Auth.signOut();
    removeCookie(switchedOrganisationCookieName);
    return left(true);
  } catch (error: any) {
    return right(error);
  }
};

export const getActiveOrganisation = (
  identity: IdentityState
): Organisation | false => {
  const user = GetUserIfLoggedIn(identity);
  if (!user) {
    return false;
  }
  const org = user.organisations.find((o) => o.active);
  if (org) {
    return org;
  }
  return false;
};

const setActiveOrganisation = (
  organisations: Organisation[],
  organisation?: Organisation
): Organisation[] => {
  if (!organisation) {
    // If no org was chosen, just pick the first
    const orgs: Organisation[] = organisations.map((o) => {
      return {
        name: o.name,
        organisationId: o.organisationId,
        active: false,
      };
    });
    orgs[0].active = true;
    return orgs;
  }
  const f = (o: Organisation, s: Organisation): Organisation => {
    return {
      name: o.name,
      organisationId: o.organisationId,
      active: o.organisationId === s.organisationId,
    };
  };
  const orgs = organisations.map((o) => f(o, organisation));

  // If we ended up with no active orgs, just pick the first one
  if (!orgs.find((o) => o.active === true)) {
    orgs[0].active = true;
  }

  return orgs;
};

export const switchOrganisation = async (
  organisation: Organisation,
  identityState: IdentityState,
  setUserState: Dispatch<SetStateAction<IdentityState>>,
  update: (props?: Partial<IntercomProps>) => void
) => {
  const user = GetUserIfLoggedIn(identityState);
  if (!user) {
    return;
  }
  update({
    company: {
      companyId: organisation.organisationId,
      name: organisation.name,
    },
    customAttributes: {
      current_organisation: organisation.name,
    },
  });
  setUserState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    cognitoSub: user.cognitoSub,
    isAdmin: user.isAdmin, // TODO
    organisations: setActiveOrganisation(user.organisations, organisation),
    cognitoUser: user.cognitoUser,
    onboarding: user.onboarding,
    onboardingComplete: user.onboardingComplete,
  });
};
