import { Box } from "@mui/material";
import booleanIntersects from "@turf/boolean-intersects";
import { Basemap } from "components/basemap/basemap";
import { requestTransformer } from "pages/geoscape-data/explorer/explorer-page";
import { ZoomControl } from "pages/geoscape-data/explorer/zoom-control";
import { useCallback, useRef, useState } from "react";
import {
  LngLatBoundsLike,
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
  MapRef,
  PointLike,
} from "react-map-gl";
import { useHistory } from "react-router";
import * as Routes from "routes";
import { ActionCard } from "./cards/action-card";
import { FeatureCard } from "./cards/feature-card";
import { WelcomeCard } from "./cards/welcome-card";
import { BuildingsSource } from "./sources/buildings-source";
import { CadastreSource } from "./sources/cadastre-source";
import { EasementsSource } from "./sources/easements-source";
import { GnafSource } from "./sources/gnaf-source";

export const Easements = () => {
  const initialCoordinates = {
    latitude: -25.2744,
    longitude: 133.7751,
  };

  const demoCoordinates = {
    latitude: -35.3864,
    longitude: 149.0615,
  };

  const boundingBox: LngLatBoundsLike = [148.977, -35.4555, 149.146, -35.3173];

  const initialZoom: number = 4;
  const demoZoom: number = 14;
  const minZoom: number = 4;
  const [dynamicZoom, setDynamicZoom] = useState(4);
  const [showWelcome, setShowWelcome] = useState(true);
  const [showFeatureCard, setShowFeatureCard] = useState(false);

  const [coordinates, setCoordinates] = useState(initialCoordinates);
  const [cadastrePid, setCadastrePid] = useState("");
  const [addressId, setAddressId] = useState("");

  const [intersectingEasements, setIntersectingEasements] = useState<
    MapboxGeoJSONFeature[] | undefined
  >();

  const mapRef = useRef<MapRef>(null);
  const history = useHistory();

  const resetCompass = useCallback(() => {
    mapRef.current?.easeTo({
      bearing: 0,
      pitch: 0,
    });
  }, [mapRef]);

  const handleZoom = useCallback(
    (zoom: number) => {
      setDynamicZoom(zoom);
      mapRef.current?.flyTo({ zoom: zoom, duration: 500 });
    },
    [mapRef, setDynamicZoom]
  );

  const handleExplore = () => {
    setShowWelcome(false);

    mapRef.current?.flyTo({
      center: [demoCoordinates.longitude, demoCoordinates.latitude],
      duration: 5000,
      zoom: demoZoom,
    });
  };

  const distinct = (
    value: MapboxGeoJSONFeature,
    index: number,
    array: MapboxGeoJSONFeature[]
  ) => {
    if (value.geometry.type === "LineString")
      return (
        index ===
        array.findIndex(
          (e) =>
            e.properties?.easement_line_pid ===
            value.properties?.easement_line_pid
        )
      );
    return (
      index ===
      array.findIndex(
        (e) =>
          e.properties?.easement_polygon_pid ===
          value.properties?.easement_polygon_pid
      )
    );
  };

  const onClick = (event: MapLayerMouseEvent) => {
    const bbox = [
      [event.point.x, event.point.y],
      [event.point.x, event.point.y],
    ];

    const bboxGnaf = [
      [event.point.x - 50, event.point.y - 50],
      [event.point.x + 50, event.point.y + 50],
    ];

    // First find the cadastre feature user clicked on
    const selectedFeatures = mapRef.current?.queryRenderedFeatures(
      bbox as [PointLike, PointLike],
      { layers: ["cadastre_fill"] }
    );

    if (!selectedFeatures || selectedFeatures.length === 0) {
      setCadastrePid("");
      return;
    }

    const cadastreFeature = selectedFeatures[0];

    // Also find the G-NAF feature user clicked on
    const selectedGnafFeatures = mapRef.current?.queryRenderedFeatures(
      bboxGnaf as [PointLike, PointLike],
      { layers: ["gnaf"] }
    );

    // Find the intersecting G-NAF addresses
    if (selectedGnafFeatures && selectedGnafFeatures.length > 0) {
      const intersectingGnafAddresses = selectedGnafFeatures
        .filter(
          (address) =>
            address &&
            booleanIntersects(
              address.geometry as any,
              cadastreFeature.geometry as any
            )
        )
        .filter(distinct);

      if (intersectingGnafAddresses && intersectingGnafAddresses.length > 0) {
        setAddressId(
          intersectingGnafAddresses[0].properties?.ADDRESS_DETAIL_PID
        );
      } else {
        setAddressId("");
      }
    }

    setCadastrePid(cadastreFeature.properties?.cadastre_pid ?? "");
    setShowFeatureCard(true);

    // Then find all the easements line features
    const allEasementsLine = mapRef.current?.querySourceFeatures("easements", {
      sourceLayer: "easements_line",
    });

    // Then find all the easements polygon features
    const allEasementsPolygon = mapRef.current?.querySourceFeatures(
      "easements",
      {
        sourceLayer: "easements_polygon",
      }
    );

    if (allEasementsPolygon && allEasementsLine) {
      const intersectingEasements = [
        ...allEasementsPolygon,
        ...allEasementsLine,
      ]
        .filter(
          (easement) =>
            easement &&
            booleanIntersects(
              easement.geometry as any,
              cadastreFeature.geometry as any
            )
        )
        .filter(distinct);
      setIntersectingEasements(intersectingEasements);
    }
  };

  return (
    <Basemap
      mapRef={mapRef}
      attributionControl={true}
      latitude={coordinates.latitude}
      longitude={coordinates.longitude}
      zoom={initialZoom}
      onZoom={(zoom) => setDynamicZoom(zoom)}
      onClick={onClick}
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
      }}
      minZoom={minZoom}
      boundingBox={boundingBox}
      transformRequest={requestTransformer}
    >
      {showWelcome && (
        <WelcomeCard
          onExit={() => {
            history.push(Routes.demosPage);
          }}
          onExplore={handleExplore}
        />
      )}
      {showFeatureCard && (
        <FeatureCard
          cadastrePid={cadastrePid}
          addressId={addressId}
          intersectingEasements={intersectingEasements}
          onClose={() => setShowFeatureCard(false)}
        />
      )}
      {!showWelcome && (
        <Box
          sx={{
            left: 15,
            bottom: 40,
            position: "absolute",
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            justifyContent: "flex-end",
            paddingLeft: "10px",
          }}
        >
          <ActionCard />
        </Box>
      )}
      <Box
        sx={{
          right: 15,
          top: 80,
          position: "absolute",
          display: "flex",
          gap: "10px",
          flexDirection: "column",
          justifyContent: "flex-end",
          paddingLeft: "10px",
        }}
      >
        <ZoomControl
          resetCompass={resetCompass}
          dynamicZoom={dynamicZoom}
          onZoom={handleZoom}
        />
      </Box>
      <EasementsSource intersectingEasements={intersectingEasements} />
      <GnafSource />
      <CadastreSource cadastrePid={cadastrePid} />
      <BuildingsSource />
    </Basemap>
  );
};
