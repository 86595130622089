import { Alert, Grid, Skeleton } from "@mui/material";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { DemoConfiguration } from "data/models";
import { fetchDemos } from "data/queries";
import addressAutocompleteDark from "icons/address-autocomplete-dark.png";
import addressAutocompleteLight from "icons/address-autocomplete-light.png";
import easementsDark from "icons/easements-dark.png";
import easementsLight from "icons/easements-light.png";
import emissionsMetric from "icons/emissionsMetric.jpg";
import floodDark from "icons/flood-dark.png";
import floodLight from "icons/flood-light.png";
import localityAutocompleteDark from "icons/locality-autocomplete-dark.png";
import localityAutocompleteLight from "icons/locality-autocomplete-light.png";
import nabDark from "icons/nab-dark.png";
import nabLight from "icons/nab-light.png";
import propertyInsightsDark from "icons/property-insights-dark.png";
import propertyInsightsLight from "icons/property-insights-light.png";
import { useQuery } from "react-query";
import * as Routes from "routes";
import { resolveIdentityId } from "utils/identity";
import { Easements } from "../demos/easements/easements";
import { EmissionMetricPage } from "../demos/emission-metric/emision-metric-page";
import { FloodDataPage } from "../demos/flood-data/flood-data-page";
import { NabLandUsePage } from "../demos/nab-land-use/nab-land-use-page";
import { predictiveAddress } from "../demos/predictive-address/predictive-address-demo";
import { predictiveSuburb } from "../demos/predictive-suburb/predictive-suburb-demo";
import { PropertyInsights } from "../demos/property-insights/property-insights";
import { DemoCard } from "./demo-card";

export interface DemoItem {
  id: string;
  title: string;
  categories: string[];
  description: string;
  path: string;
  darkThumbnail: string;
  lightThumbnail: string;
  component: React.ComponentType<any>;
}

// Id should match the id in backend
export const DataDemos: DemoItem[] = [
  {
    id: "emissions-metric",
    title: "Emissions Metric",
    categories: ["Climate"],
    description:
      "Visualise emissions metrics for property portfolios, supporting environmental compliance and informed decision making.",
    path: Routes.emissionsMetric,
    darkThumbnail: emissionsMetric,
    lightThumbnail: emissionsMetric,
    component: EmissionMetricPage,
  },
  {
    id: "predictive-address",
    title: "Address Autocomplete",
    categories: ["Risk", "UX", "Service Delivery", "Ecommerce"],
    description:
      "Predictive address auto-completion with real-time map visualisation. Enhance user experience and operational efficiency.",
    path: Routes.predictiveAddress,
    darkThumbnail: addressAutocompleteDark,
    lightThumbnail: addressAutocompleteLight,
    component: predictiveAddress,
  },
  {
    id: "predictive-locality",
    title: "Locality Autocomplete",
    categories: ["Risk", "UX", "Service Delivery", "Ecommerce"],
    description:
      "Predictive locality auto-completion with real-time map visualisation. Enhance user experience and operational efficiency.",
    path: Routes.predictiveLocality,
    darkThumbnail: localityAutocompleteDark,
    lightThumbnail: localityAutocompleteLight,
    component: predictiveSuburb,
  },
  {
    id: "easements",
    title: "Easements",
    categories: ["Utilities", "Access", "Rights", "Development"],
    description:
      "Explore our easement data demo — gain property insights to streamline planning and identify restrictions",
    path: Routes.easements,
    darkThumbnail: easementsDark,
    lightThumbnail: easementsLight,
    component: Easements,
  },
  {
    id: "flood-data-demo",
    title: "Flood Data Demo",
    categories: [],
    description:
      "See flood risk through new eyes. Our interactive demonstration reveals how the Ingham community could and was affected by flooding, using sophisticated modelling provided by JBA and class-leading spatial insights from Geoscape that go beyond traditional risk assessments.",
    path: Routes.floodDataDemo,
    darkThumbnail: floodDark,
    lightThumbnail: floodLight,
    component: FloodDataPage,
  },
  {
    id: "nab-land-use",
    title: "NAB land use",
    categories: [],
    description: "",
    path: Routes.nabLandUse,
    darkThumbnail: nabDark,
    lightThumbnail: nabLight,
    component: NabLandUsePage,
  },
  {
    id: "property-insights",
    title: "Property Insights",
    categories: [],
    description: "",
    path: Routes.propertyInsights,
    darkThumbnail: propertyInsightsDark,
    lightThumbnail: propertyInsightsLight,
    component: PropertyInsights,
  },
];

export const DemosPage = () => {
  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const demosQuery = useQuery([QueryKeys.demosKey, identityId], () =>
    fetchDemos(identityState)
  );

  const getDemoItem = (demoId: string): DemoItem | undefined => {
    return DataDemos.find((item) => item.id === demoId);
  };

  return (
    <Grid container spacing={2} alignItems="stretch">
      {demosQuery.isLoading &&
        DataDemos.map((demoItem: DemoItem, index: number) => (
          <Grid item xs={12} md={6} key={index}>
            <Skeleton variant="rectangular" height="250px" />
          </Grid>
        ))}
      {demosQuery.isSuccess &&
        demosQuery.data.map((demoItem: DemoConfiguration, index: number) => (
          <Grid item xs={12} md={6} key={index}>
            {getDemoItem(demoItem.id) !== undefined && (
              <DemoCard
                {...getDemoItem(demoItem.id)!}
                status={demoItem.status}
              ></DemoCard>
            )}
          </Grid>
        ))}
      {demosQuery.isError && (
        <Alert variant="outlined" severity="error">
          An error occurred while fetching the demos.
        </Alert>
      )}
    </Grid>
  );
};
