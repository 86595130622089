import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Dispatch, SetStateAction, useRef } from "react";
import { MapRef, Marker } from "react-map-gl";

// import "../popup.css";

import { useFormLogic } from "./cards/custom-hooks";
// import { Season, Style } from "./cards/types";
import { Box } from "@mui/material";
import { Basemap } from "components/basemap/basemap";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";
import { requestTransformer } from "pages/geoscape-data/explorer/explorer-page";
import { ZoomControl } from "pages/geoscape-data/explorer/zoom-control";
import { ActionCard } from "./cards/action-card";
import { useFloodDemoState } from "./custom-hooks/useFloodDemoState";
import { useMapControls } from "./custom-hooks/useMapControls";
import { MapLayer, stepsDemos } from "./step-config-file/stepConfigFile";
import { GeoJsonDemoMapLayer, Mode } from "./step-config-file/types";

export const FloodDataPage = () => {
  const FREE_EXPLORE_STEP: number = 2;
  const COMPARE_MAPS_STEP: number = 3;

  const initialZoom: number = 12;

  const {
    demoStep,
    attributes,
    dynamicZoom,
    markerPosition,
    markerShow,
    selectedLayer,
    setDemoStep,
    setAttributes,
    setDynamicZoom,
    setMarkerPosition,
    setShowMarker,
    setSelectedLayer,
  } = useFloodDemoState();

  const currentStepDemo = stepsDemos[demoStep];
  const previousStepDemo = stepsDemos[demoStep - 1] ?? stepsDemos[1];
  const nextStepDemo = stepsDemos[demoStep + 1] ?? stepsDemos[1];
  const CurrentComponentStep = currentStepDemo.component;
  const currentMapConfigStep = currentStepDemo.mapConfig;

  const mapRef = useRef<MapRef>(null);

  const nextStep = (setDemoStep: Dispatch<SetStateAction<number>>) => {
    setDemoStep((prevStep) => {
      return prevStep + 1;
    });
  };

  const backStep = (setDemoStep: Dispatch<SetStateAction<number>>) => {
    setDemoStep((prevStep) => {
      return prevStep - 1;
    });
  };

  const isInteractiveStep =
    currentStepDemo.id === FREE_EXPLORE_STEP ||
    currentStepDemo.id === COMPARE_MAPS_STEP;

  // useMapControls -> Has the logic for Fly To, Map Interaction Controls
  const {
    boundingBox,
    minZoom,
    coordinates,
    resetCompass,
    handleZoom,
    handleMarker,
    onClick,
    moveToNextLocation,
    highlightedLayer,
    selectedId,
  } = useMapControls({
    nextStepDemo,
    mapRef,
    setAttributes,
    setSelectedLayer,
    setMarkerPosition,
    setShowMarker,
    setDynamicZoom,
    isInteractiveStep,
    initialCoordinates: currentStepDemo.coordinates,
  });

  const { formState, handleFormChange } = useFormLogic({
    // people: 1,
    // season: Season.Summer,
    mode: Mode.ffe,
  });

  const { enqueueQueryFailed } = useCustomSnackbars();

  return (
    // Here we define the layers. Layers that go on the top of the map and containt the GeoJson information - Geometry, Properties, etc.
    <>
      <Basemap
        mapRef={mapRef}
        attributionControl={true}
        latitude={coordinates.latitude}
        longitude={coordinates.longitude}
        zoom={initialZoom}
        onZoom={(zoom) => setDynamicZoom(zoom)}
        onClick={onClick}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
        }}
        minZoom={minZoom}
        //boundingBox={boundingBox}
        transformRequest={requestTransformer}
      >
        {/* Display the Card Component defined in the Steps */}
        <CurrentComponentStep
          moveToNextLocation={moveToNextLocation}
          nextStep={() => nextStep(setDemoStep)}
          backStep={() => backStep(setDemoStep)}
          previousCoordinates={previousStepDemo.coordinates}
          previousZoomLevel={previousStepDemo.mapConfig.zoomLevel}
          nextCoordinates={nextStepDemo.coordinates}
          zoomLevel={nextStepDemo.mapConfig.zoomLevel}
          attributes={attributes}
          selectedLayer={selectedLayer}
          handleMarker={() => handleMarker()}
          formState={formState} // This contain the style key word
          {...currentStepDemo}
          // {...currentStepDemo} // Uncomment to pass all properties from currentStepDemo
        />

        {/* The Marker and the Action Card is display in the Free Explore Step */}
        {currentStepDemo.id == FREE_EXPLORE_STEP && (
          <>
            {markerShow && (
              <Marker
                key={"selected-address"}
                longitude={markerPosition.longitude}
                latitude={markerPosition.latitude}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                }}
              >
                <LocationOnIcon fontSize="large" color="error" />
              </Marker>
            )}

            <Box
              sx={{
                left: 15,
                bottom: 40,
                position: "absolute",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                justifyContent: "flex-end",
                paddingLeft: "10px",
              }}
            >
              <ActionCard
                formState={formState}
                onFormChange={handleFormChange}
              />
            </Box>
            <Box
              sx={{
                right: 15,
                top: 80,
                position: "absolute",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                justifyContent: "flex-end",
                paddingLeft: "10px",
              }}
            >
              <ZoomControl
                resetCompass={resetCompass}
                dynamicZoom={dynamicZoom}
                onZoom={handleZoom}
              />
            </Box>

            {currentStepDemo.geojsonLayers &&
              currentStepDemo.geojsonLayers.map(
                (layer: GeoJsonDemoMapLayer) => {
                  return (
                    <MapLayer
                      geojsonPath={layer.geojsonPath}
                      formState={formState}
                      layer_name={layer.layerName}
                      highlightedLayer={highlightedLayer}
                      style={layer.createStyle(formState, selectedId)}
                      beforeId={layer.beforeId}
                    />
                  );
                }
              )}
          </>
        )}
      </Basemap>
    </>
  );
};
