// Is on board or not

interface alreadyOnBoard {
  // This is the interface for the OnBoard component
  onBoard: boolean;
}

export const services = [
  "Address Validation",
  "App Integration",
  "Bulk Geocoding/Validation",
  "Data Clipping",
  "Data Downloading",
  "Data Exploration/Visualization",
  "Demos & Prototypes",
];

export const industries = [
  "Advertising",
  "Agriculture",
  "Architecture",
  "Construction",
  "Defense & Space",
  "Education & Training",
  "Emergency Services",
  "Energy",
  "Engineering",
  "Environmental Services",
  "Events",
  "Financial Services",
  "Geospatial and Location Intelligence",
  "Government - Commonwealth",
  "Government - Local",
  "Government - State & Territory",
  "Healthcare",
  "Information Technology & Services",
  "Insurance",
  "Not-for-Profit & Charity",
  "Management Consulting",
  "Manufacturing",
  "Mining",
  "Modelling",
  "Property & Real Estate",
  "Retail Trade",
  "Surveying",
  "Telecommunications",
  "Transport & Logistics",
  "Urban & Town Planning",
  "Utilities",
];

export const workDescription = [
  "Account Manager",
  "Business Analyst",
  "CIO",
  "Consultant",
  "CTO",
  "Data Engineer",
  "Data Scientist",
  "DBA",
  "DevOps",
  "Director",
  "GIS Analyst",
  "GIS Director",
  "GIS Manager",
  "Other",
  "Product Manager",
  "Project Manager",
  "Sales",
  "Software Engineer",
  "Support Engineer",
];
