import * as echarts from "echarts";
import { useEffect, useRef } from "react";
import useDarkMode from "use-dark-mode";

interface ChartStats {
  cad_total: number;
  building_total: number;
  ffe_total: number;
  flooded_parcels: number;
  flooded_buildings: number;
  ffe_saved: number;
}

const stats: Record<string, ChartStats> = {
  "ffe": {
    cad_total: 0,
    building_total: 3854,
    ffe_total: 0,
    flooded_parcels: 0,
    flooded_buildings: 0,
    ffe_saved: 0,
  },
  "real": {
    cad_total: 3367,
    building_total: 3854,
    ffe_total: 1359,
    flooded_parcels: 1159,
    flooded_buildings: 1735,
    ffe_saved: 379,
  },
  "20": {
    cad_total: 3367,
    building_total: 3854,
    ffe_total: 1359,
    flooded_parcels: 64,
    flooded_buildings: 88,
    ffe_saved: 34,
  },
  "50": {
    cad_total: 3367,
    building_total: 3854,
    ffe_total: 1359,
    flooded_parcels: 709,
    flooded_buildings: 1062,
    ffe_saved: 340,
  },
  "100": {
    cad_total: 3367,
    building_total: 3854,
    ffe_total: 1359,
    flooded_parcels: 887,
    flooded_buildings: 1356,
    ffe_saved: 382,
  },
  "200": {
    cad_total: 3367,
    building_total: 3854,
    ffe_total: 1359,
    flooded_parcels: 1111,
    flooded_buildings: 1693,
    ffe_saved: 440,
  },
  "500": {
    cad_total: 3367,
    building_total: 3854,
    ffe_total: 1359,
    flooded_parcels: 1331,
    flooded_buildings: 2077,
    ffe_saved: 460,
  },
  "1500": {
    cad_total: 3367,
    building_total: 3854,
    ffe_total: 1359,
    flooded_parcels: 1556,
    flooded_buildings: 2464,
    ffe_saved: 458,
  },
};

interface ChartProps {
  data_key: string;
}

const nice_names = {
  cad_total: "Total Parcels",
  building_total: "Total Buildings",
  ffe_total: "Buildings w/ FFE",
  flooded_parcels: "Flooded Parcels",
  flooded_buildings: "Flooded Buildings",
  ffe_saved: "Flood < FFE",
};
const RadialPieChart = (props: ChartProps) => {
  let dataset = stats[props.data_key];
  const chartRef = useRef(null);

  const darkMode = useDarkMode(false);
  const isDarkMode = darkMode.value;

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    const options = {
      title: {
        left: "center",
        top: "center",
        text: `{title1|${dataset.building_total.toLocaleString()}}\n{title2|Buildings}`,

        textStyle: {
          rich: {
            title1: {
              color: isDarkMode ? "#fff" : "#000",
              fontSize: 24,
            },
            title2: {
              color: isDarkMode ? "#fff" : "#000",
              fontSize: 16,
            },
          },
        },
      },
      tooltip: {
        trigger: "item",
        formatter: function (params: any) {
          return `${params.name}: ${params.value} <br> ${(
            (params.value / dataset.building_total) *
            100
          ).toLocaleString("en-US", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })}%`;
        },
      },
      series: [
        {
          type: "pie",
          radius: ["40%", "70%"], // Donut chart style
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: "inside",
            formatter: (params: any) =>
              params.value === 0
                ? ""
                : props.data_key == "ffe"
                ? "Total Buildings"
                : params.name,
            color: isDarkMode ? "#fff" : "black",
            fontWeight: "bold",
            textBorderColor: !isDarkMode ? "white" : "black",
            textBorderWidth: 3,
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: dataset.flooded_buildings - dataset.ffe_saved,
              name: "Flooded",
              itemStyle: { color: "#EF476F" },
            },
            {
              value: dataset.ffe_saved,
              name: "FFE Above Flood",
              itemStyle: { color: "#CD64FA" },
            },
            {
              value: dataset.building_total - dataset.flooded_buildings,
              name: "Outside Flood",
              itemStyle: {
                color: props.data_key == "ffe" ? "grey" : "#06D6A0",
              },
            },
          ],
        },
      ],
      backgroundColor: !isDarkMode ? "white" : "black",
    };

    chartInstance.setOption(options);

    // Cleanup on component unmount
    return () => {
      chartInstance.dispose();
    };
  }, [props.data_key, isDarkMode]);

  return <div ref={chartRef} style={{ width: 300, height: 300 }} />;
};

export default RadialPieChart;
