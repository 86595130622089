import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import { logOut } from "components/navigation/menu-bar";
import {
  getOrganisations,
  switchedOrganisationCookieName,
  useIdentity,
  User,
} from "contexts/identity-context";
import * as QueryKeys from "data";
import GeoscapeDeveloperSvg from "icons/hub-logo-signIn.svg?react";
import { usePostHog } from "posthog-js/react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import { useIntercom } from "react-use-intercom";
import { UserInvitationsOnBoarding } from "./user-invitations-onboarding";

// This page is for the users who already gone through the onboarding process, and then got removed from all organisations.
export const OnboardingCompleteSwitch = () => {
  const theme = useTheme();
  const [identityState, setUserState] = useIdentity();

  const isDark = theme.palette.mode === "dark";
  const user = identityState as User;
  const membershipsQuery = useQuery(
    [QueryKeys.userMembership, user.cognitoSub],
    () => getOrganisations(user)
  );
  const [hasHandledInvitations, setHasHandledInvitations] = useState(false);
  const [_c, setCookie, removeCookie] = useCookies([
    switchedOrganisationCookieName,
  ]);
  const { update, shutdown } = useIntercom();
  const posthog = usePostHog();

  return (
    <>
      <div
        style={{
          // backgroundImage: `url('data:image/svg+xml;utf8, ${svgString}')`,
          backgroundRepeat: "repeat",
          backgroundSize: "cover",
          width: "100%",
          height: "100vh",
          position: "relative",
        }}
      >
        <Box
          alignItems="center"
          height="100%"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "64px",
            backgroundColor: isDark ? "black" : "white",
          }}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              backgroundColor: isDark ? "black" : "white",
            }}
          >
            {useLocation().pathname !== "/sign-up" && (
              <GeoscapeDeveloperSvg
                fill={isDark ? "white" : "black"}
                style={{ width: "450px", height: "100px", padding: "0px" }}
              />
            )}
          </Box>
          <div style={{ height: "32px" }}></div>
          <Box
            sx={{
              width: "410px",
            }}
          >
            {membershipsQuery.isSuccess &&
              membershipsQuery.data.length !== 0 && (
                <Card>
                  <CardHeader
                    title="Organisation Invites"
                    sx={{
                      padding: "32px 32px 0px 32px !important",
                    }}
                  />
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "32px 32px 16px 32px !important",
                      alignItems: "flex-start",
                      gap: "16px",
                      alignSelf: "stretch",
                    }}
                  >
                    <UserInvitationsOnBoarding
                      userState={user}
                      setUserState={setUserState}
                      setHasHandledInvitations={setHasHandledInvitations}
                    >
                      <Alert severity="warning" variant="outlined">
                        You must be part of an organisation to use Geoscape Hub.
                        To create a new organisation, contact
                        support@geoscape.com.au.
                      </Alert>
                    </UserInvitationsOnBoarding>
                  </CardContent>
                  <CardActions
                    sx={{
                      paddingTop: 0,
                      paddingRight: 4,
                      paddingBottom: 4,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setUserState((prevState) => {
                          const user = prevState as User;
                          return {
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            cognitoSub: user.cognitoSub,
                            isAdmin: user.isAdmin,
                            organisations: user.organisations,
                            cognitoUser: user.cognitoUser,
                            onboarding: false,
                            onboardingComplete: user.onboardingComplete,
                          };
                        });
                      }}
                      disabled={!hasHandledInvitations}
                    >
                      Continue
                    </Button>
                  </CardActions>
                </Card>
              )}
            {membershipsQuery.isSuccess &&
              membershipsQuery.data.length == 0 && (
                <Card>
                  <CardHeader
                    title="Organisation Invites"
                    sx={{
                      padding: "32px 32px 0px 32px !important",
                    }}
                  />
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "32px 32px 16px 32px !important",
                      alignItems: "flex-start",
                      gap: "16px",
                      alignSelf: "stretch",
                    }}
                  >
                    <Typography variant="body2">
                      Pending organisation invitations
                    </Typography>
                    <Alert severity="warning" variant="outlined">
                      You have no pending organisation invites. To create a new
                      organisation, contact support@geoscape.com.au.
                    </Alert>
                  </CardContent>
                  <CardActions
                    sx={{
                      paddingTop: 0,
                      paddingRight: 4,
                      paddingBottom: 4,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        logOut(setUserState, removeCookie, shutdown, posthog)
                      }
                    >
                      Sign Out
                    </Button>
                  </CardActions>
                </Card>
              )}
          </Box>
        </Box>
      </div>
    </>
  );
};
