export const ffeBuildingHeightStyle = (selectedId: string | null) => {
  return {
    "fill-extrusion-color": [
      "case",
      ["==", ["get", "building_pid"], selectedId],
      "hsla(80, 1.3%, 46.3%, 1)", // Highlight color for the selected building
      "hsla(80, 1.3%, 54.3%, 1)", // Default color
    ],
    "fill-extrusion-height": [
      "+",
      ["coalesce", ["get", "first_floor_elevation"], 0],
      ["get", "roof_height"],
    ],
    "fill-extrusion-base": [
      "+",
      ["coalesce", ["get", "first_floor_elevation"], 0],
    ],
    "fill-extrusion-opacity": 1,
  };
};

export const ffeBuildingFFEStyle = (selectedId: string | null) => {
  return {
    "fill-extrusion-color": [
      "case",
      ["==", ["get", "building_pid"], selectedId],
      "hsla(282, 94%, 61%, 1)", // Highlight color for the selected building
      "hsla(282, 94%, 69%, 1)", // Default color
    ],
    "fill-extrusion-height": [
      "+",
      ["coalesce", ["get", "first_floor_elevation"], 0],
    ],
    "fill-extrusion-opacity": 1,
  };
};

export const ffeCadastreStyle = () => {
  return {
    "line-color": "grey",
    "line-width": 1,
    "line-opacity": 1,
  };
};

export const realBuildingHeightStyle = (selectedId: string | null) => {
  return {
    "fill-extrusion-color": [
      /**
       * This has inner case statement,
       * Outer case checks the if the building_pid == selectedId can display a darker color for all the inner cases.
       */
      "case",
      ["==", ["get", "building_pid"], selectedId],
      [
        "case",
        // Case 1: If flood_level is -9999, always use the default color
        ["==", ["get", "flood_height"], -9999],
        "hsla(164, 95%, 35%, 1)",

        // Case 2: Flood level is above the building base but below the first floor elevation (raised house)
        [
          "all",
          [">", 6.1, ["to-number", ["get", "building_base"]]],
          [
            "<=",
            6.1,
            [
              "+",
              ["to-number", ["get", "building_base"]],
              ["coalesce", ["get", "first_floor_elevation"], 0],
            ],
          ],
        ],
        "hsla(282, 94%, 61%, 1)",

        // Case 3: Flood level is above the first floor elevation (submerged)
        [
          ">",
          6.1,
          [
            "+",
            ["to-number", ["get", "building_base"]],
            ["coalesce", ["get", "first_floor_elevation"], 0],
          ],
        ],
        "hsla(346, 84%, 53%, 1)",

        // Default case: Flood level is below the building base
        "hsla(164, 95%, 35%, 1)",
      ],
      // Case 1: If flood_level is -9999, always use the default color
      ["==", ["get", "flood_height"], -9999],
      "hsla(164, 95%, 43%, 1)",

      // Case 2: Flood level is above the building base but below the first floor elevation (raised house)
      [
        "all",
        [">", 6.1, ["to-number", ["get", "building_base"]]],
        [
          "<=",
          6.1,
          [
            "+",
            ["to-number", ["get", "building_base"]],
            ["coalesce", ["get", "first_floor_elevation"], 0],
          ],
        ],
      ],
      "hsla(282, 94%, 69%, 1)",

      // Case 3: Flood level is above the first floor elevation (submerged)
      [
        ">",
        6.1,
        [
          "+",
          ["to-number", ["get", "building_base"]],
          ["coalesce", ["get", "first_floor_elevation"], 0],
        ],
      ],
      "hsla(346, 84%, 61%, 1)",

      // Default case: Flood level is below the building base
      "hsla(164, 95%, 43%, 1)",
    ],
    "fill-extrusion-height": [
      "+",
      ["to-number", ["get", "building_base"]],
      ["coalesce", ["get", "first_floor_elevation"], 0],
      ["get", "roof_height"],
    ],
    "fill-extrusion-base": [
      "+",
      ["to-number", ["get", "building_base"]],
      ["coalesce", ["get", "first_floor_elevation"], 0],
    ],
    "fill-extrusion-opacity": 1,
  };
};

export const realBuildingFFEStyle = (selectedId: string | null) => {
  return {
    "fill-extrusion-color": [
      "case",
      ["==", ["get", "building_pid"], selectedId],
      "hsla(80, 1%, 44%, 1)", // Highlight color for the selected building
      "hsla(80, 1%, 54%, 1)", // Default color
    ],
    "fill-extrusion-height": [
      "+",
      ["coalesce", ["get", "first_floor_elevation"], 0],
      ["to-number", ["get", "building_base"]],
    ],
    "fill-extrusion-base": ["to-number", ["get", "building_base"]],
    "fill-extrusion-opacity": 1,
  };
};

export const realCadastreStyle = () => {
  return {
    "fill-extrusion-color": [
      "case",
      ["==", ["get", "parcel_type"], "Road"],
      "hsla(180, 2%, 73%, 1)", // Darker shade if its a road
      "hsla(202, 20%, 92%, 1)", // Default color
    ],
    "fill-extrusion-height": ["coalesce", ["get", "building_base"], 5.5],
    "fill-extrusion-opacity": 1,
  };
};

export const realFloodStyle = () => {
  return {
    "fill-extrusion-color": "hsla(186, 78%, 43%, 1)",
    "fill-extrusion-height": 6.1,
    "fill-extrusion-base": 0,
    "fill-extrusion-opacity": 0.8,
  };
};

export const modelBuildingHeightStyle = (
  year: string,
  selectedId: string | null
) => {
  const floodAttr = `River_RP${year}_mean`;
  return {
    "fill-extrusion-color": [
      /**
       * This has inner case statement,
       * Outer case checks the if the building_pid == selectedId can display a darker color for all the inner cases.
       */
      "case",
      ["==", ["get", "building_pid"], selectedId],
      [
        "case",        [
          ">",
          ["to-number", ["get", floodAttr]],
          ["to-number", ["coalesce", ["get", "first_floor_elevation"], 0]],
        ],
        [
          "interpolate",
          ["linear"],
          ["to-number", ["get", floodAttr]], // Use floodAttr for interpolation
          0,
          "hsla(164, 95%, 35%, 1)",
          0.001,
          "hsla(42, 100%, 62%, 1)",
          2,
          "hsla(346, 84%, 53%, 1)",
        ],
        // If floodAttr > 0 and floodAttr < first_floor_elevation, color it purple
        [
          "all",
          [">", ["to-number", ["get", floodAttr]], 0],
          [
            "<",
            ["to-number", ["get", floodAttr]],
            ["to-number", ["coalesce", ["get", "first_floor_elevation"], 0]],
          ],
        ],
        "hsla(282, 94%, 61%, 1)",

        // Default color
        "hsla(164, 95%, 35%, 1)",
      ],

      // If floodAttr > first_floor_elevation, color it blue
      [
        ">",
        ["to-number", ["get", floodAttr]],
        ["to-number", ["coalesce", ["get", "first_floor_elevation"], 0]],
      ],
      [
        "interpolate",
        ["linear"],
        ["to-number", ["get", floodAttr]], // Use floodAttr for interpolation
        0,
        "hsla(164, 95%, 43%, 1)",
        0.001,
        "hsla(42, 100%, 70%, 1)",
        2,
        "hsla(346, 84%, 61%, 1)",
      ],

      // If floodAttr > 0 and floodAttr < first_floor_elevation, color it purple
      [
        "all",
        [">", ["to-number", ["get", floodAttr]], 0],
        [
          "<",
          ["to-number", ["get", floodAttr]],
          ["to-number", ["coalesce", ["get", "first_floor_elevation"], 0]],
        ],
      ],
      "hsla(282, 94%, 69%, 1)",

      // Default color
      "hsla(164, 95%, 43%, 1)",
    ],
    "fill-extrusion-height": [
      "+",
      ["to-number", ["get", "building_base"]],
      ["coalesce", ["get", "first_floor_elevation"], 0],
      ["get", "roof_height"],
    ],
    "fill-extrusion-base": [
      "+",
      ["to-number", ["get", "building_base"]],
      ["coalesce", ["get", "first_floor_elevation"], 0],
    ],
    "fill-extrusion-opacity": 1,
  };
};

export const modelBuildingFFEStyle = (
  year: string,
  selectedId: string | null
) => {
  return {
    "fill-extrusion-color": [
      "case",
      ["==", ["get", "building_pid"], selectedId],
      "hsla(80, 1%, 44%, 1)", // Highlight color for the selected building
      "hsla(80, 1%, 54%, 1)", // Default color
    ],
    "fill-extrusion-height": [
      "+",
      ["coalesce", ["get", "first_floor_elevation"], 0],
      ["to-number", ["get", "building_base"]],
    ],
    "fill-extrusion-base": ["to-number", ["get", "building_base"]],
    "fill-extrusion-opacity": 1,
  };
};

export const modelCadastreStyle = (year: string) => {
  const floodAttr = `River_RP${year}_mean`;
  return {
    "fill-extrusion-color": [
      "case",
      // Ignore roads (keep default color)
      ["==", ["get", "parcel_type"], "Road"],
      "hsla(180, 2%, 73%, 1)",

      [">", ["to-number", ["get", floodAttr]], 0],
      [
        "interpolate",
        ["linear"],
        ["get", floodAttr],
        0,
        "hsla(202, 20%, 92%, 1)",
        0.001,
        "hsla(186, 78%, 64%, 1)",
        2,
        "hsla(198, 79%, 43%, 1)",
      ],

      // Default color
      "hsla(202, 20%, 92%, 1)",
    ],
    "fill-extrusion-height": ["coalesce", ["get", "building_base"], 5.5],
    "fill-extrusion-opacity": 1,
  };
};

export const modelFloodStyle = () => {
  return {};
};
