import { CognitoUser } from "@aws-amplify/auth";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Link,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { Auth } from "aws-amplify";
import { BaseState } from "common/models";
import { convertToHubUser } from "contexts/identity-context";
import * as localStorageKeys from "data/local-storage-keys";
import { Form, Formik } from "formik";
import { PostHog } from "posthog-js";
import { usePostHog } from "posthog-js/react";
import queryString from "query-string";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation } from "react-router";
import * as Routes from "routes";
import { InferType, boolean, object, string } from "yup";
import { useIdentity } from "../../contexts/identity-context";
import { MfaLogin } from "./mfa-login";
import { SetMessageState, useMessageState } from "./use-message-state";

// import background from "./Background.svg"
// import { boolean, InferType, object, string } from "yup";
const minPasswordLength = 8;

export const SignInValidationSchema = object({
  email: string().email("Enter a valid email").required("Email is required"),
  password: string()
    .min(
      minPasswordLength,
      `Password should be of minimum ${minPasswordLength} characters length`
    )
    .required("Password is required"),
  termsOfUse: boolean(),
});

type SignInValues = InferType<typeof SignInValidationSchema>;

export interface SignInState extends BaseState {
  isTermsOfUseRequired: boolean;
}

async function signIn(
  event: SignInValues,
  setState: Dispatch<SetStateAction<SignInState>>,
  setMessage: SetMessageState,
  setMfaRequired: Dispatch<SetStateAction<boolean>>,
  setUser: Dispatch<SetStateAction<CognitoUser | null>>,
  posthog: PostHog
) {
  setState((currentState) => ({ ...currentState, isLoading: true }));
  try {
    const cognitoUser = await Auth.signIn(
      event.email.toLowerCase(),
      event.password,
      {
        "custom:dev_t_and_c": String(event.termsOfUse),
      }
    );

    setUser(cognitoUser);

    setMessage({ text: "", type: null });

    if (
      cognitoUser.challengeName === "SMS_MFA" ||
      cognitoUser.challengeName === "SOFTWARE_TOKEN_MFA"
    ) {
      setMfaRequired(true);
      return;
    }
    posthog.capture("user_signed_in");
    const user = await convertToHubUser(cognitoUser);
    return user;
  } catch (error: any) {
    if (error.message.includes("terms_of_use_failure")) {
      setState((currentState) => ({
        ...currentState,
        isTermsOfUseRequired: true,
      }));
      setMessage({
        type: "error",
        // text: "Terms of use has not been accepted.",
        text: "Please accept Geoscape Hub's Terms of Use to continue.",
      });
    } else if(error.code === "UserNotFoundException") {
        setMessage({
          type: "error",
          text: "Invalid username or password. Please try again.",
        });
    } else if (error.code === "NotAuthorizedException") {
        if (error.message.includes("disabled")) {
          setMessage({
            type: "error",
            text: "Account disabled. Please contact support",
          });
        } else {
          setMessage({
            type: "error",
            text: "Invalid username or password. Please try again.",
          });
        }
    } else if (error.code === "UserNotConfirmedException") {
      setMessage({
        type: "error",
        text: "This account needs to be verified before signing in. Check your email or use the 'Resend Verification' form to send a new confirmation.",
      });
    } else {
      console.error(`User sign-in failure: ${error.message}`);
      setMessage({
        type: "error",
        text: "We encountered an unexpected error. Please try again in a few minutes or contact support",
      });
    }
    posthog.capture("user_failed_signing_in");
  } finally {
    setState((currentState) => ({ ...currentState, isLoading: false }));
  }
}

export const SignIn = () => {
  const initialState: SignInState = {
    isLoading: false,
    isError: false,
    message: "",
    isTermsOfUseRequired: false,
  };

  const [state, setState] = useState<SignInState>(initialState);

  const [_, setUserState] = useIdentity();

  const [message, setMessage] = useMessageState({ type: null, text: "" });
  const { search } = useLocation();

  const setLoading = (isLoading: boolean) =>
    setState((currentState) => ({ ...currentState, isLoading }));

  useEffect(() => {
    (async function () {
      try {
        const params = queryString.parse(search);

        if (params.code && params.username) {
          setLoading(true);
          const username = String(params["username"]);
          const code = String(params["code"]);
          await Auth.confirmSignUp(username, code);
          setMessage({
            type: "success",
            text: "Your account is successfully confirmed.",
          });
        }
      } catch (e) {
        setMessage({
          type: "error",
          text: "An error occurred during account confirmation.",
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [search]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [mfaRequired, setMfaRequired] = useState(false);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const optOutMfa = localStorage.getItem(localStorageKeys.optOutMfa);

  const mfaSetupRequired = user && !user.challengeName && optOutMfa !== "true";

  const posthog = usePostHog();

  return (
    <>
      {!mfaRequired && !mfaSetupRequired && (
        <Formik<SignInValues>
          initialValues={{ email: "", password: "", termsOfUse: false }}
          onSubmit={async (e) => {
            await signIn(
              e,
              setState,
              setMessage,
              setMfaRequired,
              setUser,
              posthog
            ).then((user) => {
              if (user) {
                setUserState(user);
              }
            });
          }}
          validationSchema={SignInValidationSchema}
        >
          {({
            values: { email, password, termsOfUse },
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => (
            <Form id="signinForm">
              <Card
                style={{
                  padding: "0px",
                  borderRadius: "32px 32px 32px 32px",
                  width: "410px",
                  maxWidth: "500px",

                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <CardHeader
                  sx={{
                    padding: "32px 32px 0px 32px !important",
                  }}
                  titleTypographyProps={{ variant: "h5" }}
                  title="Sign into your account"
                />
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "var(--4,32px) !important",
                    alignItems: "flex-start",
                    gap: "16px",
                    alignSelf: "stretch",
                  }}
                >
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    type="text"
                    value={email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    disabled={state.isLoading}
                    fullWidth
                    sx={customStyle}
                    InputProps={{
                      sx: {
                        borderRadius: "8px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      },
                    }}
                  />
                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    disabled={state.isLoading}
                    fullWidth
                    sx={customStyle}
                    InputProps={{
                      sx: {
                        borderRadius: "8px",
                        height: "40px",
                      },
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />

                  {state.isTermsOfUseRequired ? (
                    <>
                      <Alert
                        variant="outlined"
                        severity={"info"}
                        sx={{
                          borderRadius: "8px",
                        }}
                      >
                        It seems you have not accepted our "terms of use" or
                        there have been changes to it since you last accepted
                        it. Please review it again and tick the box below.
                      </Alert>

                      <FormControlLabel
                        sx={{
                          display: "flex",
                          height: "38px",
                          margin: "0px",
                        }}
                        control={
                          <Checkbox
                            color="primary"
                            id="termsOfUse"
                            size="small"
                            name="termsOfUse"
                            checked={termsOfUse}
                            onChange={handleChange}
                          />
                        }
                        label={
                          <>
                            {"I agree to Geoscape Hub's "}
                            <a
                              href="https://geoscape.com.au/legal/geoscape-developer-terms"
                              target="_blank"
                              rel="noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              Terms of Use
                            </a>
                          </>
                        }
                      />
                    </>
                  ) : null}

                  {state.isLoading ? (
                    <Box width="100%" display="flex" justifyContent="center">
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      {message.text === "" || !message.type ? null : (
                        <Alert
                          variant="outlined"
                          severity={message.type}
                          sx={{
                            borderRadius: "8px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            alignSelf: "stretch",
                          }}
                        >
                          <Typography variant="body2">
                            {message.text}
                          </Typography>
                        </Alert>
                      )}
                      <Button
                        id="sign-in"
                        variant="contained"
                        type="submit"
                        disabled={state.isLoading}
                        fullWidth
                        sx={{ borderRadius: "8px", height: "36px" }}
                        children="Sign In"
                      />
                    </>
                  )}

                  <ButtonGroup
                    variant="text"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "stretch",
                      borderRadius: "4px",
                    }}
                    aria-label="Basic button group"
                  >
                    <Button
                      LinkComponent={Link}
                      size="small"
                      color="primary"
                      href={Routes.signUp}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",

                        padding: "4px 5px",
                        gap: "8px",
                        fontSize: "12px",
                      }}
                    >
                      Sign up
                    </Button>

                    <Button
                      LinkComponent={Link}
                      size="small"
                      color="primary"
                      href={Routes.resetPassword}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        padding: "4px 5px",
                        gap: "8px",
                        fontSize: "12px",
                      }}
                    >
                      Password Reset
                    </Button>

                    <Button
                      LinkComponent={Link}
                      size="small"
                      color="primary"
                      href={Routes.resendVerification}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",

                        padding: "4px 5px",
                        gap: "8px",
                        fontSize: "12px",
                      }}
                    >
                      Resend Verification
                    </Button>
                  </ButtonGroup>
                  {/* </Box> */}
                </CardContent>
              </Card>
            </Form>
          )}
        </Formik>
      )}

      {mfaRequired && (
        <MfaLogin
          onSubmit={async (cognitoUser) => {
            const user = await convertToHubUser(cognitoUser);
            setMessage({ text: "", type: null });
            setMfaRequired(false);
            setUserState(user);
          }}
          user={user}
        />
      )}
    </>
  );
};

const customStyle: SxProps<Theme> = {
  [`& .MuiInputLabel-root `]: {
    top: "-5px",
  },

  [`& .MuiInputLabel-shrink `]: {
    top: "0px",
  },
};
