import { Box } from "@mui/material";
import { CloseButton } from "components/snackbars/CloseButton";
import { SupportButton } from "components/snackbars/SupportButton";
import { SnackbarKey, useSnackbar, VariantType } from "notistack";

function useCustomSnackbars() {
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Enqueue a custom support snackbar
   * @param message This is the message you want to display followed by ", please contact support."
   * @param variant This is the variant of the snackbar (default "info")
   */
  const enqueueSupportSnackbar = (
    message: string,
    variant: VariantType = "info"
  ) => {
    enqueueSnackbar(`${message}`, {
      variant: variant,
      action: (snackbarId: SnackbarKey) => (
        <Box>
          <SupportButton />
          <CloseButton snackbarId={snackbarId} />
        </Box>
      ),
    });
  };

  const enqueueNotImplementedSnackbar = () => {
    enqueueSupportSnackbar(
      "Page currently not available, please contact support."
    );
  };

  const enqueueLinkCorruptedSnackbar = () => {
    enqueueSupportSnackbar(
      "Link is corrupted, please contact support.",
      "error"
    );
  };

  const enqueueQueryFailed = (message: string) => {
    enqueueSnackbar(`Query failed. ${message}`, {
      variant: "error",
    });
  };

  const enqueueMutationFailed = (message: string) => {
    enqueueSnackbar(`Mutation failed. ${message}`, {
      variant: "error",
    });
  };

  return {
    enqueueSupportSnackbar,
    enqueueNotImplementedSnackbar,
    enqueueLinkCorruptedSnackbar,
    enqueueQueryFailed,
    enqueueMutationFailed,
  };
}

export { useCustomSnackbars };
