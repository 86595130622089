import { Button, CssBaseline, Snackbar } from "@mui/material";
import * as Sentry from "@sentry/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Amplify from "aws-amplify";
import { ApplicationLoading } from "components/loading";
import { SignedIn } from "components/signed-in";
import { SignedOut } from "components/signed-out";
import { CloseButton } from "components/snackbars/CloseButton";
import { OnboardingCompleteSwitch } from "components/user/sign-in/onboarding-complete-switch";
import OnboardingWizard from "components/user/sign-in/onboarding-wizard";
import { IdentityProvider, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { fetchLatestVersion } from "data/queries";
import { SnackbarProvider } from "notistack";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useState } from "react";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { GeoscapeDeveloperThemeProvider } from "theme";

import.meta.env.VITE_SENTRY_DSN &&
  import.meta.env.VITE_ENVIRONMENT &&
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
  });

// configure auth settings ...
Amplify.configure({
  Auth: {
    region: import.meta.env.VITE_AWS_REGION,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_AWS_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    cookieStorage: {
      domain: window.location.hostname,
      path: "/",
      expires: 365,
      sameSite: "lax",
      secure: true,
    },
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
});

const intercomAppId: string = import.meta.env.VITE_INTERCOM_APP_ID
  ? import.meta.env.VITE_INTERCOM_APP_ID
  : "";

const queryClient = new QueryClient();

posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  person_profiles: "identified_only",
});

// configure stripe settings ...
const stripePromise = loadStripe(
  import.meta.env.VITE_STRIPE_PUBLIC_KEY
    ? import.meta.env.VITE_STRIPE_PUBLIC_KEY
    : ``
);

const currentVersion = `v2.0.0.${import.meta.env.VITE_BUILD_NO}`;

const UserStateSwitch = () => {
  /**
   * Conditionally returns components depending if we're signed in or not
   */

  const [userState] = useIdentity();
  const [showUpdate, setShowUpdate] = useState(false);
  const [countdown, setCountdown] = useState(15);

  /**
   *
   * @param str check if the version string matches our conditions
   * @returns
   */
  const isValidVersionString = (str: string) => {
    if (
      str === undefined ||
      str === null ||
      str.trim() === "" ||
      str.includes("undefined")
    ) {
      return false;
    }
    // Regex pattern for `v2.0.0.1234` format
    const versionPattern = /^v\d+\.\d+\.\d+\.\d+$/;
    if (!versionPattern.test(str)) {
      return false; // The string does not matches the version format
    }
    return true; // The string is valid
  };

  const latestVersionQuery = useQuery(
    [QueryKeys.latestVersion],
    () => fetchLatestVersion(),
    {
      onSuccess: (latestVersion: string) => {
        if (latestVersion == "local") {
          return;
        }
        // check if the version strings are unsafe to do a force reload
        if (!isValidVersionString(latestVersion)) {
          return;
        }
        if (!isValidVersionString(currentVersion)) {
          return;
        }
        if (latestVersion !== currentVersion) {
          setShowUpdate(true);
          // Start countdown
          const interval = setInterval(() => {
            setCountdown((prev) => {
              if (prev === 1) {
                clearInterval(interval);
                window.location.reload();
              }
              return prev - 1;
            });
          }, 1000);
        }
      },
      refetchInterval: 15000,
      refetchIntervalInBackground: true,
    }
  );

  const switcher = (() => {
    switch (userState) {
      case false:
        return <SignedOut />;
      case "loading":
        return <ApplicationLoading />;
      default:
        if (userState.onboarding === true) {
          if (userState.onboardingComplete == "true") {
            return <OnboardingCompleteSwitch />;
          } else {
            return <OnboardingWizard />;
          }
        }
        return <SignedIn />;
    }
  })();

  return (
    <>
      <Snackbar
        open={showUpdate}
        message={`A new version is available. Reloading in ${countdown}s...`}
        action={
          <Button
            color="secondary"
            size="small"
            onClick={() => window.location.reload()}
          >
            Refresh Now
          </Button>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      {switcher}
    </>
  );
};

function App() {
  return (
    <div className="App">
      <PostHogProvider client={posthog}>
        <QueryClientProvider client={queryClient}>
          <IntercomProvider appId={intercomAppId}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={6000}
              action={(snackbarId) => <CloseButton snackbarId={snackbarId} />}
            >
              <Router>
                <GeoscapeDeveloperThemeProvider>
                  <CssBaseline />
                  <Elements stripe={stripePromise}>
                    <CookiesProvider>
                      <IdentityProvider>
                        <UserStateSwitch />
                      </IdentityProvider>
                    </CookiesProvider>
                  </Elements>
                </GeoscapeDeveloperThemeProvider>
              </Router>
            </SnackbarProvider>
          </IntercomProvider>
          <ReactQueryDevtools position="top-right" />
        </QueryClientProvider>
      </PostHogProvider>
    </div>
  );
}

export default App;
