import { CognitoUser } from "@aws-amplify/auth";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { GALTextInput } from "components/library/gal-text-input";
import {
  User,
  getOrganisations,
  useIdentity,
  userCreateOrganisation,
} from "contexts/identity-context";
import * as QueryKeys from "data";
import GeoscapeDeveloperSvg from "icons/hub-logo-signIn.svg?react";
import { usePostHog } from "posthog-js/react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import { MfaSetupOnboarding } from "./mfa-setup-onboarding";
import { industries, services, workDescription } from "./states";
import { UserInvitationsOnBoarding } from "./user-invitations-onboarding";

export interface CreateOrganisationBody {
  workDescription: string;
  selectedServices: string[];
  industry: string;
  name: string;
}

export const setCompletedWizardFlag = async (user: CognitoUser) => {
  await Auth.updateUserAttributes(user, {
    "custom:onboardingComplete": String(true),
  });
};

const OnboardingWizard = () => {
  const [identityState, setUserState] = useIdentity();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const posthog = usePostHog();

  const user = identityState as User;

  const [activeStep, setActiveStep] = useState<number>(0);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [hasHandledInvitations, setHasHandledInvitations] = useState(false);

  const membershipsQuery = useQuery(
    [QueryKeys.userMembership, user.cognitoSub],
    () => getOrganisations(user)
  );

  const [formData, setFormData] = useState<{
    workDescription: string;
    selectedServices: string[];
  }>({
    workDescription: "",
    selectedServices: [],
  });

  const [formData2, setFormData2] = useState({
    name: "",
    industry: "",
  });

  const [isMfaSetup, setisMfaSetup] = useState(false);
  const [startFromStep, setStartFromStep] = useState(1);

  const steps = ["Profile", "Organization", "MFA"];

  const validateStep = () => {
    const newErrors: Record<string, string> = {};

    switch (activeStep) {
      case 0:
        if (!formData.workDescription.trim()) {
          newErrors.workDescription = "Work description is required";
        }
        if (formData.selectedServices.length === 0) {
          newErrors.selectedServices = "Please select at least one service";
        }
        break;
      case 1:
        // First we check is there is invitations
        if (!membershipsQuery.isSuccess) {
          newErrors.loading = "Loading...";
          break;
        }
        if (membershipsQuery.data.length === 0) {
          if (!formData2.name.trim()) {
            newErrors.name = "Organization name is required";
          }
          if (!formData2.industry) {
            newErrors.industry = "Industry is required";
          }
          break;
        }
        // If there are invitations, are those invitations handled
        if (hasHandledInvitations == false) {
          newErrors.invitations =
            "Ensure that all invitations are processed before proceeding with the next steps.";
          break;
        }

        break;
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async () => {
    if (!validateStep()) {
      return;
    }
    setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
  };

  const handleBack = () => {
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  const handleInputChange = (field: string) => (event: any) => {
    const value =
      field === "selectedServices"
        ? Array.isArray(event.target.value)
          ? event.target.value
          : event.target.value.split(",")
        : event.target.value;

    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    // Clear error when user starts typing
    if (errors[field]) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  const handleInputChange2 = (field: string) => (event: any) => {
    const value = event.target.value;
    setFormData2((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    // Clear error when user starts typing
    if (errors[field]) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  const handleCreateOrganisation = async (name: string) => {
    setIsLoading(true);
    try {
      const newOrg = await userCreateOrganisation({
        ...formData,
        ...formData2,
      });
      return newOrg;
    } catch (error) {
      console.error("Error creating organisation", error);
      setErrors((prev) => ({
        ...prev,
        organisation: "Failed to create organization. Please try again.",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "346px",
              }}
            >
              <Typography variant="body1" sx={{ padding: "0px" }}>
                Tell us a bit about yourself
              </Typography>
              <FormControl
                sx={{ width: "346px", padding: "0px" }}
                error={!!errors.workDescription}
              >
                <InputLabel id="role-label">
                  What best describes your work?
                </InputLabel>
                <Select
                  labelId="role-label"
                  id="role-multi-select"
                  value={formData.workDescription}
                  onChange={handleInputChange("workDescription")}
                  input={
                    <OutlinedInput label="What best describes your work?" />
                  }
                  disabled={!membershipsQuery.isSuccess}
                >
                  {workDescription
                    .sort((a, b) => a.localeCompare(b))
                    .map((service, index) => (
                      <MenuItem key={index} value={service}>
                        {service}
                      </MenuItem>
                    ))}
                </Select>
                {errors.workDescription && (
                  <FormHelperText>{errors.workDescription}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                sx={{ width: "346px", padding: "0px" }}
                error={!!errors.selectedServices}
              >
                <InputLabel id="services-label">
                  What Hub services are you most interested in?
                </InputLabel>
                <Select
                  labelId="services-label"
                  id="services-multi-select"
                  multiple
                  value={formData.selectedServices}
                  onChange={handleInputChange("selectedServices")}
                  input={
                    <OutlinedInput label="What Hub services are you most interested in?" />
                  }
                  disabled={!membershipsQuery.isSuccess}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {services
                    .sort((a, b) => a.localeCompare(b))
                    .map((service, index) => (
                      <MenuItem key={index} value={service}>
                        <Checkbox
                          checked={formData.selectedServices.includes(service)}
                        />
                        <ListItemText primary={service} />
                      </MenuItem>
                    ))}
                </Select>
                {errors.selectedServices && (
                  <FormHelperText>{errors.selectedServices}</FormHelperText>
                )}
              </FormControl>
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "346px",
              }}
            >
              {!membershipsQuery.isSuccess ? (
                <></>
              ) : user.organisations.length !== 0 &&
                hasHandledInvitations == true ? (
                // <h1>Organisation  accepted</h1>
                <Alert
                  severity="info"
                  variant="outlined"
                  sx={{
                    display: "flex",
                    padding: "6px 16px",
                    alignItems: "flex-start",
                    alignSelf: "stretch",
                    width: "346px",
                  }}
                >
                  All invitations processed
                </Alert>
              ) : membershipsQuery.data.length !== 0 ? (
                <>
                  <UserInvitationsOnBoarding
                    userState={user}
                    setUserState={setUserState}
                    setHasHandledInvitations={setHasHandledInvitations}
                  />
                  {errors.invitations && (
                    <Typography color="error">{errors.invitations}</Typography>
                  )}
                </>
              ) : (
                <>
                  <Typography variant="body1" sx={{ padding: "0px" }}>
                    Tell us about your organisation
                  </Typography>
                  <div>
                    <GALTextInput
                      id="organisation-name"
                      name="Organisation Name?"
                      label="Organisation Name?"
                      type="text"
                      margin="normal"
                      value={formData2.name}
                      error={!!errors.name}
                      onChange={handleInputChange2("name")}
                      helperText={errors.name}
                      variant="outlined"
                      characterLimit={64}
                      disabled={isLoading}
                      fullWidth
                      sx={{ padding: "0px", margin: "0px" }}
                    />
                    <FormControl
                      sx={{ width: "100%", padding: "0px" }}
                      error={!!errors.industry}
                    >
                      <InputLabel id="industry-label">Industry</InputLabel>
                      <Select
                        labelId="industry-label"
                        id="industry-select"
                        value={formData2.industry}
                        label="Industry"
                        onChange={handleInputChange2("industry")}
                        disabled={isLoading}
                      >
                        {industries.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.industry && (
                        <FormHelperText>{errors.industry}</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </>
              )}
            </Box>
          </>
        );

      case 2:
        return (
          <>
            <Typography
              variant="body2"
              sx={{ width: "346px" }}
              color="text.secondary" // Apply light/secondary text color
            >
              Geoscape recommends you protect your account with MFA (Multi
              Factor Authentication).
            </Typography>
            <Alert
              severity="info"
              variant="outlined"
              sx={{
                display: "flex",
                padding: "6px 16px",
                alignItems: "flex-start",
                alignSelf: "stretch",
                width: "346px",
                border: "1px solid var(--Light-Info-Main, #0288D1)",
              }}
            >
              MFA is optional but recommended for security.
            </Alert>
          </>
        );

      default:
        return <Typography>Step {activeStep + 1} content</Typography>;
    }
  };
  // Call handleCreateOrganisation
  // Set user state in identity
  // Set completed wizard flag
  const completeWizard = async () => {
    if (user.organisations.length === 0) {
      await handleCreateOrganisation(formData2.name).then(async (newOrg) => {
        if (!newOrg) return;
        setUserState((prevState) => {
          const user = prevState as User;
          return {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            cognitoSub: user.cognitoSub,
            isAdmin: user.isAdmin,
            organisations: [
              {
                name: newOrg.name,
                organisationId: newOrg.organisationId,
                active: true,
              },
            ],
            cognitoUser: user.cognitoUser,
            onboarding: false,
            onboardingComplete: String(true),
          };
        });
      });
    } else {
      setUserState((prevState) => {
        return {
          ...(prevState as User),
          onboarding: false,
          onboardingComplete: String(true),
        };
      });
    }

    await setCompletedWizardFlag(user.cognitoUser);
  };

  return (
    <>
      <div
        style={{
          // backgroundImage: `url('data:image/svg+xml;utf8, ${svgString}')`,
          backgroundRepeat: "repeat",
          backgroundSize: "cover",
          width: "100%",
          height: "100vh",
          position: "relative",
        }}
      >
        <Box
          alignItems="center"
          height="100%"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "64px",
            backgroundColor: isDark ? "black" : "white",
          }}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              backgroundColor: isDark ? "black" : "white",
            }}
          >
            {useLocation().pathname !== "/sign-up" && (
              <GeoscapeDeveloperSvg
                fill={isDark ? "white" : "black"}
                style={{ width: "450px", height: "100px", padding: "0px" }}
              />
            )}
          </Box>
          <div style={{ height: "32px" }}></div>
          <Box
            // display="flex"

            sx={{
              display: { xs: "block", md: "flex" },
              gap: "32px",
            }}
          >
            {errors.organisation ? (
              <Alert severity="error" variant="outlined">
                Failed to create organisation. Contact support or try again
                later
              </Alert>
            ) : isMfaSetup ? (
              <MfaSetupOnboarding
                startFromStep={startFromStep}
                onSubmit={completeWizard}
                onOptOut={completeWizard}
                cognitoUser={user.cognitoUser}
              />
            ) : (
              <Card>
                <CardHeader
                  sx={{
                    padding: "32px 32px 0px 32px !important",
                  }}
                  title="Onboarding Wizard"
                />
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "var(--4,32px) !important",
                    alignItems: "flex-start",
                    gap: "16px",
                    alignSelf: "stretch",
                  }}
                >
                  <Stepper
                    activeStep={activeStep}
                    sx={{
                      display: "flex",
                      width: "346px",
                      gap: "8px",
                      alignSelf: "stretch",
                    }}
                  >
                    {steps.map((label) => (
                      <Step key={label} sx={{ padding: "0px" }}>
                        <StepLabel sx={{ padding: "0px" }}>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                  {!membershipsQuery.isSuccess ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        py: 4,
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    renderStepContent()
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button
                      disabled={
                        activeStep === 0 ||
                        isLoading ||
                        !membershipsQuery.isSuccess
                      }
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                    {activeStep !== steps.length - 1 ? (
                      <LoadingButton
                        variant="contained"
                        onClick={handleNext}
                        loading={isLoading}
                        disabled={
                          isLoading ||
                          !membershipsQuery.isSuccess ||
                          activeStep === 0
                            ? formData.selectedServices.length == 0 ||
                              !formData.workDescription
                            : !formData2.industry || !formData2.name
                        }
                      >
                        {!membershipsQuery.isSuccess ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Continue"
                        )}
                      </LoadingButton>
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "8px",
                          }}
                        >
                          <Button
                            id="optOutMfa"
                            disabled={isLoading}
                            onClick={async () => {
                              setStartFromStep(-1);
                              setisMfaSetup(true);
                              posthog.capture("user_hub_onboarding", {
                                workDescription: formData.workDescription,
                                selectedServices: formData.selectedServices,
                                firstLoginAt: new Date().toISOString(),
                              });
                            }}
                          >
                            {isLoading ? (
                              <CircularProgress size={24} />
                            ) : (
                              "OPT OUT"
                            )}
                          </Button>
                          <Button
                            variant="contained"
                            disabled={isLoading}
                            onClick={async () => {
                              setStartFromStep(1);
                              setisMfaSetup(true);
                              posthog.capture("user_hub_onboarding", {
                                workDescription: formData.workDescription,
                                selectedServices: formData.selectedServices,
                                firstLoginAt: new Date().toISOString(),
                              });
                            }}
                          >
                            {isLoading ? (
                              <CircularProgress size={24} />
                            ) : (
                              "Setup MFA"
                            )}
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </CardContent>
              </Card>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
};

export default OnboardingWizard;
