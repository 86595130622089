import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Dispatch, SetStateAction, useRef } from "react";
import { MapRef, Marker } from "react-map-gl";

// import "../popup.css";

import { useFormLogic } from "./cards/custom-hooks";
// import { Season, Style } from "./cards/types";
import { Box } from "@mui/material";
import { Basemap } from "components/basemap/basemap";
import { ZoomControl } from "pages/geoscape-data/explorer/zoom-control";
import { FormComponent } from "./cards/action-card";
import { useEmissionMetricState } from "./custom-hooks/useEmissionMetricState";
import { useMapControls } from "./custom-hooks/useMapControls";
import { MapLayer, stepsDemos } from "./step-config-file/stepConfigFile";
import { Season, Style } from "./step-config-file/types";

export const EmissionMetricPage = () => {
  const nextStep = (setDemoStep: Dispatch<SetStateAction<number>>) => {
    setDemoStep((prevStep) => {
      return prevStep + 1;
    });
  };

  const backStep = (setDemoStep: Dispatch<SetStateAction<number>>) => {
    setDemoStep((prevStep) => {
      return prevStep - 1;
    });
  };

  const FREE_EXPLORE_STEP: number = 2;
  const COMPARE_MAPS_STEP: number = 3;

  const initialZoom: number = 4;

  const {
    demoStep,
    attributes,
    dynamicZoom,
    markerPosition,
    markerShow,
    setDemoStep,
    setAttributes,
    setDynamicZoom,
    setMarkerPosition,
    setShowMarker,
  } = useEmissionMetricState();

  const currentStepDemo = stepsDemos[demoStep];
  const previousStepDemo = stepsDemos[demoStep - 1] ?? stepsDemos[1];
  const nextStepDemo = stepsDemos[demoStep + 1] ?? stepsDemos[1];
  const CurrentComponentStep = currentStepDemo.component;
  const currentMapConfigStep = currentStepDemo.mapConfig;

  const mapRef = useRef<MapRef>(null);

  const isInteractiveStep =
    currentStepDemo.id === FREE_EXPLORE_STEP ||
    currentStepDemo.id === COMPARE_MAPS_STEP;

  // useMapControls -> Has the logic for Fly To, Map Interaction Controls
  const {
    boundingBox,
    minZoom,
    coordinates,
    resetCompass,
    handleZoom,
    handleMarker,
    onClick,
    moveToNextLocation,
    highlightedLayer,
  } = useMapControls({
    nextStepDemo,
    mapRef,
    setAttributes,
    setMarkerPosition,
    setShowMarker,
    setDynamicZoom,
    isInteractiveStep,
  });

  const { formState, handleFormChange } = useFormLogic({
    extrude: true,
    people: 1,
    season: Season.Summer,
    style: Style.Co2Annually,
  });

  return (
    // Here we define the layers. Layers that go on the top of the map and containt the GeoJson information - Geometry, Properties, etc.
    <>
      <Basemap
        mapRef={mapRef}
        attributionControl={true}
        latitude={coordinates.latitude}
        longitude={coordinates.longitude}
        zoom={initialZoom}
        onZoom={(zoom) => setDynamicZoom(zoom)}
        onClick={onClick}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
        }}
        minZoom={minZoom}
        boundingBox={boundingBox}
      >
        <CurrentComponentStep
          moveToNextLocation={moveToNextLocation}
          nextStep={() => nextStep(setDemoStep)}
          backStep={() => backStep(setDemoStep)}
          previousCoordinates={previousStepDemo.coordinates}
          previousZoomLevel={previousStepDemo.mapConfig.zoomLevel}
          nextCoordinates={nextStepDemo.coordinates}
          zoomLevel={nextStepDemo.mapConfig.zoomLevel}
          attributes={attributes}
          handleMarker={() => handleMarker()}
          formState={formState}
          {...currentStepDemo}
          // {...currentStepDemo} // Uncomment to pass all properties from currentStepDemo
        />

        {currentStepDemo.id == FREE_EXPLORE_STEP && (
          <>
            {markerShow && (
              <Marker
                key={"selected-address"}
                longitude={markerPosition.longitude}
                latitude={markerPosition.latitude}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                }}
              >
                <LocationOnIcon fontSize="large" color="error" />
              </Marker>
            )}

            <Box
              sx={{
                left: 15,
                bottom: 40,
                position: "absolute",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                justifyContent: "flex-end",
                paddingLeft: "10px",
              }}
            >
              <FormComponent
                formState={formState}
                onFormChange={handleFormChange}
              />
            </Box>
            <Box
              sx={{
                right: 15,
                top: 80,
                position: "absolute",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                justifyContent: "flex-end",
                paddingLeft: "10px",
              }}
            >
              <ZoomControl
                resetCompass={resetCompass}
                dynamicZoom={dynamicZoom}
                onZoom={handleZoom}
              />
            </Box>

            {currentStepDemo.geojsonPath && (
              <MapLayer
                geojsonPath={currentStepDemo.geojsonPath}
                formState={formState}
                layer_name={currentStepDemo.layer_name}
                highlightedLayer={highlightedLayer}
              />
            )}
          </>
        )}
      </Basemap>
    </>
  );
};
