import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { SelectAddressArgs } from "../step-config-file/types";
// import { FormState } from "./types";

import { useState } from "react";

interface FormComponentProps {
  moveToNextLocation: (args: SelectAddressArgs) => void;
  setboundingBox: (props: any) => void;
  setBasemap: (props: any) => void;
}
// (args: SelectAddressArgs) => void

interface Coordinates {
  latitude: number;
  longitude: number;
}

interface LocationData {
  name: string;
  coordinates: Coordinates;
  bbox?: number[];
}

export const FormComponent: React.FC<FormComponentProps> = ({
  setboundingBox,
  setBasemap,
  moveToNextLocation,
}) => {
  const locations: LocationData[] = [
    {
      name: "MARYVALE",
      coordinates: {
        latitude: -24.660497665405273,
        longitude: 134.01792907714844,
      },
      bbox: [133.2604, -25.3281, 134.7754, -23.9929],
    },
    {
      name: "PALTALLOCH",
      coordinates: {
        latitude: -35.509664552499956,
        longitude: 139.29260605849998,
      },
      bbox: [138.7621, -35.9459, 139.8231, -35.0735],
    },
    {
      name: "CLOVER DOWNS",
      coordinates: {
        latitude: -28.406380653381348,
        longitude: 146.11844635009766,
      },
      bbox: [145.4185, -29.0578, 146.8184, -27.7549],
    },
    {
      name: "WALGETT",
      coordinates: {
        latitude: -29.8221788820501,
        longitude: 148.13796637353249,
      },
      bbox: [147.5583, -30.3106, 148.7177, -29.3337],
    },
  ];

  const handleLocationSelect = (location: string) => {
    const selectedLocation = locations.find((loc) => loc.name === location);

    if (selectedLocation) {
      moveToNextLocation({
        ...selectedLocation.coordinates,
        geocoder_attributes: {},
        zoomLevel: 10,
      });
      setboundingBox(undefined);
      setTimeout(() => {
        setboundingBox(selectedLocation.bbox);
      }, 5050);
    } else {
      console.error("Selected location is undefined");
    }
  };

  const handleBasemapChange = (basemap: string) => {
    console.log("Selected basemap:", basemap);
    setBasemap(basemap);
  };

  return (
    <AOISelector
      onLocationSelect={handleLocationSelect}
      onBasemapChange={handleBasemapChange}
    />
  );
};

interface AOISelectorProps {
  onLocationSelect?: (location: string) => void;
  onBasemapChange?: (basemap: string) => void;
}

const locations = ["WALGETT", "MARYVALE", "CLOVER DOWNS", "PALTALLOCH"];
const basemaps = ["Basemap", "Imagery"];

const AOISelector: React.FC<AOISelectorProps> = ({
  onLocationSelect,
  onBasemapChange,
}) => {
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [selectedBasemap, setSelectedBasemap] = useState<string>(basemaps[0]);

  const handleLocationClick = (location: string) => {
    setSelectedLocation(location);
    onLocationSelect?.(location);
  };

  const handleBasemapChange = (event: any) => {
    const basemap = event.target.value;
    setSelectedBasemap(basemap);
    onBasemapChange?.(basemap);
  };

  return (
    <Card
      sx={{
        width: 191,
        borderRadius: "16px",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          gap: "8px",
          flexDirection: "column",
          padding: "8px !important",
        }}
      >
        <Typography variant="subtitle2">AOI Selector</Typography>

        <ButtonGroup
          orientation="vertical"
          aria-label="Vertical button group"
          variant="contained"
        >
          {locations.map((location) => (
            <Button
              key={location}
              variant={selectedLocation === location ? "contained" : "outlined"}
              size="small"
              fullWidth
              sx={{
                bgcolor:
                  selectedLocation === location
                    ? "primary.light"
                    : "primary.main",
                color: "white",
                "&:hover": {
                  bgcolor:
                    selectedLocation === location
                      ? "primary.dark"
                      : "primary.light",
                },
                textAlign: "center",
                py: 1,
              }}
              onClick={() => handleLocationClick(location)}
            >
              {location}
            </Button>
          ))}
        </ButtonGroup>

        <FormControl
          fullWidth
          size="small"
          sx={{
            paddingTop: "8px !important",
          }}
        >
          <InputLabel size="small">Basemap</InputLabel>
          <Select
            value={selectedBasemap}
            onChange={handleBasemapChange}
            displayEmpty
            label="Basemap"
            sx={{
              // bgcolor: "background.paper",
              "& .MuiSelect-select": {
                py: 1,
              },
            }}
          >
            {basemaps.map((basemap) => (
              <MenuItem key={basemap} value={basemap}>
                {basemap}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
};
