import { Box, Button, Typography } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { useEffect, useState } from "react";
import { ClipConfigProps, ClipStepsControlProps } from "../../clip-menu";
import { Attribute } from "../../models";
import { AttributeTree } from "./attribute-tree";

export interface DataMapping {
  id: string;
  name: string;
  credits?: number;
  children?: DataMapping[];
  tooltip?: string;
}

export const dataMappings: DataMapping[] = [
  {
    id: "Address",
    name: "Address",
    children: [
      {
        id: "G-NAF Core",
        name: "G-NAF Core",
        credits: 2,
      },
    ],
  },
  {
    id: "Built Environment",
    name: "Built Environment",
    children: [
      {
        id: "buildings",
        name: "Geoscape Building Footprints",
        credits: 8,
        children: [
          {
            id: "heights",
            name: "Heights & Roofs",
            credits: 4,
            tooltip: "Buildings Footprints is required for Heights and Roofs",
          },
          {
            id: "pools",
            name: "Pools Indicator",
            credits: 4,
            tooltip: " Buildings Footprints is required for Pools Indicator",
          },
          {
            id: "solar",
            name: "Solar Indicator",
            credits: 4,
            tooltip: "Buildings Footprints is required for Solar",
          },
        ],
      },
    ],
  },
  {
    id: "Land Parcels",
    name: "Land Parcels",
    children: [
      {
        id: "cadastre",
        name: "Geoscape Cadastre",
        credits: 8,
        children: [
          {
            id: "planning",
            name: "Geoscape Planning",
            credits: 4,
            tooltip: "Geoscape Cadastre is required for Geoscape Planning",
          },
        ],
      },
      {
        id: "property",
        name: "Geoscape Property",
        credits: 8,
      },
    ],
  },
  {
    id: "Administrative Boundariess",
    name: "Administrative Boundaries",
    children: [
      {
        id: "localities",
        name: "Geoscape Localities",
        credits: 2,
      },
      {
        id: "lga",
        name: "Geoscape Local Government Areas",
        credits: 2,
      },
      {
        id: "wards",
        name: "Geoscape Wards",
        credits: 2,
      },
    ],
  },
];

export const ChooseData = (props: ClipConfigProps & ClipStepsControlProps) => {
  const [selected, setSelected] = useState<Attribute[]>(
    props.clipConfig.attributes ?? []
  );

  useEffect(() => {
    props.setClipConfig((prev) => {
      return {
        ...prev,
        attributes: selected,
      };
    });
  }, [selected]);

  const getDefaultItem = (parentId: string) => {
    switch (parentId) {
      case "buildings":
        return "footprints";
      case "cadastre":
        return "cadastre";
      default:
        return parentId;
    }
  };

  const onSelect = (id: string, _parentId: string | undefined) => {
    // clicking on root element (building, cadastre)
    const parentId = _parentId ?? id;

    if (!_parentId) {
      id = getDefaultItem(parentId);
    }

    const existingItem = selected.find((s) => s.key == parentId);
    if (existingItem) {
      // check if we already have that attribute
      //@ts-ignore doesnt know what type it
      if (existingItem.attributes.includes(id)) return;

      let newArray = [...existingItem.attributes, id];
      if (!newArray.includes(getDefaultItem(parentId))) {
        newArray.push(getDefaultItem(parentId));
      }
      const existingItemIndex = selected.findIndex((s) => s.key == parentId);
      const updatedItems = [...selected];
      updatedItems[existingItemIndex] = {
        key: existingItem.key,
        attributes: newArray,
      } as Attribute;
      setSelected(updatedItems);
    } else {
      setSelected((prev) => [
        ...prev,
        {
          key: parentId,
          attributes: [id, getDefaultItem(parentId)],
        } as Attribute,
      ]);
    }
  };

  const onDeSelect = (id: string, parentId?: string) => {
    if (!parentId) {
      const newItems = selected.filter((item: any) => item.key !== id);
      setSelected(newItems);
      return;
    }

    const existingItem = selected.find((s) => s.key == parentId);
    if (existingItem) {
      let newArray = existingItem.attributes.filter((item: any) => item !== id);
      const existingItemIndex = selected.findIndex((s) => s.key == parentId);
      const updatedItems = [...selected];
      updatedItems[existingItemIndex] = {
        key: existingItem.key,
        attributes: newArray,
      } as Attribute;
      setSelected(updatedItems);
    }
  };

  const handleChange = (checked: boolean, id: string, parentId?: string) => {
    if (checked) {
      onSelect(id, parentId);
    } else {
      onDeSelect(id, parentId);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      height="calc(86vh - 95px)"
    >
      <Typography variant="h5">Choose your Data</Typography>
      <Box sx={{ display: "flex", overflow: "auto", height: "100%" }}>
        <SimpleTreeView defaultExpandedItems={dataMappings.map((d) => d.id)}>
          {dataMappings.map((dataMapping) => (
            <TreeItem
              key={dataMapping.id}
              itemId={dataMapping.id}
              label={dataMapping.name}
            >
              <AttributeTree
                onChange={handleChange}
                selected={selected}
                dataMappings={dataMapping.children}
              />
            </TreeItem>
          ))}
        </SimpleTreeView>
      </Box>
      <Box alignSelf="flex-end" marginTop="auto" display="flex" paddingTop={1}>
        <Button
          onClick={() => props.setActiveStep(Math.max(props.activeStep - 1, 0))}
        >
          Back
        </Button>
        <Button
          id="confirm"
          disabled={selected.length == 0}
          onClick={() => {
            props.setClipConfig((prev) => {
              props.setActiveStep(
                Math.min(props.activeStep + 1, props.maxSteps)
              );
              return {
                ...prev,
                attributes: selected,
              };
            });
          }}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );
};
