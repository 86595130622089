import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertColor,
  Box,
  CardContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import ConfirmationModal from "components/modals/confirmation-modal";
import {
  IdentityState,
  User,
  getOrganisations,
  useIdentity,
} from "contexts/identity-context";
import * as QueryKeys from "data";
import { RejectedInvite, UserMembership } from "data/models";
import { userAcceptInvitation, userRejectInvitation } from "data/mutations";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { setCompletedWizardFlag } from "./onboarding-wizard";

interface Props {
  userState: User;
  setUserState: React.Dispatch<React.SetStateAction<IdentityState>>;
  setHasHandledInvitations: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}

interface Message {
  body: string;
  severity: AlertColor;
}

export const UserInvitationsOnBoarding = (props: Props) => {
  const [identity, setIdentity] = useIdentity();
  const user = identity as User;

  // Modal confirmation rejected
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const initialUserInvitation: UserMembership = {
    organisationId: "",
    userMemberId: "",
    status: "",
    organisationName: "",
    userFullName: "",
    userEmail: "",
    organisationEmail: "",
    userFirstName: "",
    userLastName: "",
  };

  const [invitation, setInvitation] = useState<UserMembership>(
    initialUserInvitation
  );

  const handleDelete = (invitation: UserMembership) => {
    setInvitation(invitation);
    setOrganisationName(invitation.organisationName);

    setDeleteOpen(true);
  };
  const [organisationName, setOrganisationName] = useState("");

  // Delete Modal State
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [messages, setMessages] = useState<Message | null>(null);

  const queryClient = useQueryClient();
  const membershipsQuery = useQuery<UserMembership[]>(
    [QueryKeys.userMembership, user.cognitoSub],
    () => getOrganisations(user)
  );

  const acceptInvitationMutation = useMutation(
    (organisationId: string) => userAcceptInvitation(organisationId, user),
    {
      onSuccess: async (newMembership) => {
        setMessages({
          severity: "success",
          body: `Invitation from "${newMembership.organisationName}" has been accepted`,
        });
        await setCompletedWizardFlag(user.cognitoUser);
        queryClient.setQueryData<UserMembership[]>(
          [QueryKeys.userMembership, user.cognitoSub],
          (oldMemberships) => {
            if (!oldMemberships) {
              return [newMembership];
            }

            const newState = oldMemberships.map((membership) => {
              if (membership.organisationId === newMembership.organisationId) {
                return newMembership;
              }
              return membership;
            });
            props.setHasHandledInvitations(
              newState.filter((x) => x.status === "Pending").length === 0
            );

            return newState;
          }
        );
        props.setUserState((prevUserState) => {
          const user = prevUserState as User;
          user.organisations = [
            ...user.organisations.map((x) => {
              return { ...x, active: false };
            }),
            {
              name: newMembership.organisationName,
              organisationId: newMembership.organisationId,
              active: false,
            },
          ];
          user.organisations[0].active = true;

          return user;
        });
      },
    }
  );

  const rejectInvitationMutation = useMutation(
    (organisationId: string) => userRejectInvitation(organisationId, user),
    {
      onSuccess: (rejectedMembership: RejectedInvite) => {
        setMessages({
          severity: "info",
          body: `Invitation from "${invitation.organisationName}" has been rejected`,
        });
        handleDeleteClose();
        queryClient.setQueryData<UserMembership[]>(
          [QueryKeys.userMembership, user.cognitoSub],
          (oldMemberships) => {
            if (!oldMemberships) {
              return [];
            }

            const newState = oldMemberships.filter(
              (membership) =>
                membership.organisationId !== rejectedMembership.organisationId
            );
            props.setHasHandledInvitations(
              newState.filter((x) => x.status === "Pending").length === 0
            );
            return newState;
          }
        );
      },
    }
  );

  const rows = !membershipsQuery.isSuccess
    ? []
    : membershipsQuery.data
        .filter((invitation) => invitation.status === "Pending")
        .map((invitation): any => {
          return {
            id: invitation.organisationName,
            invitation: invitation,
            organisationName: invitation.organisationName,
            actions: invitation.status,
          };
        })
        .sort((a, b) => {
          const nameA = a.organisationName.toUpperCase(); // ignore upper and lowercase
          const nameB = b.organisationName.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });

  const invitations = rows;

  return (
    <>
      {messages && (
        <Box>
          <Alert variant="filled" severity={messages.severity}>
            {messages.body}
          </Alert>
        </Box>
      )}

      <ConfirmationModal
        isLoading={membershipsQuery.isLoading}
        isError={membershipsQuery.isError}
        open={deleteOpen}
        // header={`Delete "${selectedKey?.name}"`}
        header={`Are you sure?`}
        body={`You won't be able to join "${organisationName}" again unless you receive another invitation.`}
        leftButtonText={"Yes, reject the invitation"}
        leftButtonColor="error"
        rightButtonText={"Cancel"}
        rightButtonColor="primary"
        handleClose={() => handleDeleteClose()}
        isLeftButtonLoading={rejectInvitationMutation.isLoading}
        handleLeftButton={() => {
          rejectInvitationMutation.mutate(invitation.organisationId);
          // rejectInvitation(
          //   invitation,
          //   userInvitationsState,
          //   setUserInvitationsState,
          //   identity,
          //   setIdentity
          // );
        }}
        handleRightButton={() => handleDeleteClose()}
      ></ConfirmationModal>

      <CardContent
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: 2,
          padding: "0px !important",
        }}
      >
        <Typography variant="body1">
          {!membershipsQuery.isLoading && rows.length == 0
            ? "No pending invitations"
            : "Pending organisation invitations"}
        </Typography>
        {props.children}
        {invitations.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            {membershipsQuery.isLoading ? (
              <LinearProgress />
            ) : (
              invitations.map((item) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>{item.organisationName}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={() =>
                        acceptInvitationMutation.mutate(
                          item.invitation.organisationId
                        )
                      }
                      loading={acceptInvitationMutation.isLoading}
                    >
                      Accept
                    </LoadingButton>
                    <LoadingButton
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => handleDelete(item.invitation)}
                      loading={rejectInvitationMutation.isLoading}
                    >
                      Reject
                    </LoadingButton>
                  </Box>
                </Box>
              ))
            )}
          </Box>
        )}
      </CardContent>
    </>
  );
};
